import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ApplicationDetail } from "../../models/applications/applicationInterfaces";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import { applicationStatusForApplicant } from "../../utils/helpers";
import { getApplicationActivity } from "../../models/applications/applicationDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { SidebarApplicationDetail } from "./sidebarApplicationDetail";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { mixpanel } from "../../utils/mixpanel";
import { selectMyUser } from "../../models/users/myUserSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import { Send } from "react-bootstrap-icons";

interface Props {
  application: ApplicationDetail;
}

interface MessageInputs {
  message: string;
}

export const ApplicantSidebar: FC<Props> = ({ application }) => {
  const { register, formState, handleSubmit, setValue } =
    useForm<MessageInputs>({ mode: "onChange" });
  const dispatch = useDispatch();
  const myUser = useSelector(selectMyUser);
  const [currentStatus, setCurrentStatus] = useState(application.status);
  useEffect(() => {
    if (
      currentStatus === "items_needed" &&
      application.status === "ready_for_partner_review"
    ) {
      (window as any).Appcues.show("-MADkV1eddEJ5BheiNpE");
    }
    setCurrentStatus(application.status);
  }, [application.status]);
  const organization = useSelector(selectOrg);

  async function sendMessage({ message }: MessageInputs) {
    try {
      await api.post(`/submissions/${application.id}/message-admin`, {
        message,
      });
      mixpanel.track("Message Sent", {
        "User Id": myUser?.userId,
        "Company Name": application.organization.name,
        "Benevolence PartnerName": application.benevolenceOrganization.name,
        "Host Name": window.location.hostname,
        Page: window.location.href,
        "Message Text": message,
      });
      setValue("message", "");
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Message Has Been Sent!",
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Message Failed. Please try again later.",
          type: "danger",
        })
      );
    }
    setTimeout(() => {
      dispatch(getApplicationActivity(application.id));
    }, 8000);
  }

  return (
    <div className="detail-sidebar d-flex flex-column">
      {/* Metadata for Page */}
      <Helmet>
        <title>My Application</title>
      </Helmet>
      <div className="sidebar-section">
        {/* Application Status */}
        <div className="sidebar-status d-flex flex-column bg-primary mt-4 px-3 py-2">
          <span className="text-uppercase h5">
            Status:{" "}
            {application.status === "approved" ? "complete" : "incomplete"}
          </span>
          <span className={"h2"} style={{ fontWeight: 800 }}>
            {applicationStatusForApplicant[application.status]}
          </span>
        </div>
        {/* Spacing Div (used because of absolute positioning in status element above) */}
        <div style={{ marginBottom: "110px" }} />
        {/* Benevolence Logo and Description - Displayed on applications marked for "Benevolence" (non-Acquire) only */}
        {!organization.meta?.acquire && (
          <>
            <div className="d-flex justify-content-center">
              {/* Logo */}
              <div className="pt-4">
                <div className="sidebar-logo-container">
                  <img
                    src={application.organization.imgUrl}
                    width={225}
                    className="mx-auto"
                  />
                </div>
              </div>
            </div>
            <span className="meta-logo px-4">
              {application.organization.name} has partnered with{" "}
              {application.benevolenceOrganization.name}, an independent
              501(c)3, to review your application and confirm you need{" "}
              <span className={"font-weight-bold"} style={{ color: "white" }}>
                {application.benevolenceOrganization.name} will keep your
                answers 100% confidential.
              </span>
            </span>
          </>
        )}

        {/* Chatbox - Need Help? */}
        <div className="p-4 d-flex flex-column">
          <span className="h5 font-weight-bold mb-0">Need Help?</span>
          <span>
            <span className="font-weight-bold">Send a message to </span>
            {application.benevolenceOrganization.name}
          </span>
          <form onSubmit={handleSubmit(sendMessage)}>
            <textarea
              ref={register({ required: true, max: 4000 })}
              cols={30}
              rows={5}
              style={{ resize: "none" }}
              className="form-control"
              name="message"
              placeholder="Type your message here"
            />
            <div className="d-flex justify-content-end mt-1">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={formState.isSubmitting || !formState.isValid}
              >
                <Send className="mr-2" />
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="sidebar-section">
        <SidebarApplicationDetail application={application} />
      </div>
    </div>
  );
};
