import { Event, Image } from "../../models/events/eventInterfaces";
import cloneDeep from "lodash/cloneDeep";

export interface NewEventValues {
  name: string;
  description: string;
  host: string;
  location: string;
  charity: string;
  eventOwnerNotifications?: string;
  fundraiserGoal: string;
  visibilityStatus: string;
}

const getNewlyFormattedImages = (newTitle: string, images: Image[]) => {
  const newImages = cloneDeep(images);

  newImages.forEach((image) => {
    // Encode the title in a way that is digestible by cloudinary.
    const newlyEncodedTitle = encodeStringForCloudinary(newTitle);

    if (!image.meta) {
      // No meta to work with.
      return;
    }

    image.meta.title = newlyEncodedTitle;

    if (!image.meta?.src) {
      // No src url to modify.
      return;
    }

    if (image.meta.custom !== false) {
      // Eject early. We only want to modify a image that has
      // custom === false 🤷‍♂️.
      return;
    }

    // Split image src url to get the title and everything after it.
    const [, secondHalf] = image.meta.src.split("$title_!");
    if (!secondHalf) {
      // We are dealing with an invalid src string.
      console.error("Invalid image src. Unable to find '$title_!'.");
      return;
    }

    // Second split to find the title closing identifier '!'.
    const [title] = secondHalf.split("!", 1);
    if (!title) {
      // We are dealing with an invalid src string.
      console.error(
        "Invalid image src. Unable to find end of title identifier ('!')."
      );
      return;
    }

    // Replace the title, with the new and encoded title.
    image.meta.src = image.meta.src.replace(title, newlyEncodedTitle);
  });

  return newImages;
};

export const preparePayloadForSubmission = (
  oldValues: Event,
  newValues: NewEventValues
): Event => {
  const newPayload = {
    location: {},
    charity: {},
    skipImageFiltering: true,
  } as Partial<Event>;

  if (oldValues.name !== newValues.name && oldValues.images) {
    newPayload.images = getNewlyFormattedImages(
      newValues.name,
      oldValues.images
    );
  }

  newPayload.name = newValues.name;
  newPayload.description = newValues.description;
  newPayload.host = newValues.host;
  newPayload.visibilityStatus = Number(newValues.visibilityStatus);
  newPayload.eventOwnerNotifications =
    newValues.eventOwnerNotifications === "true" ? true : false;

  newPayload.location!.name = newValues.location;
  newPayload.charity!.name = newValues.charity;

  // Only adjust goal if event is a fundraiser.
  if (oldValues.isFundraiser) {
    // Convert to number and assure the value has 2 decimal places.
    // We are safe from rounding issues in this case as the user is only
    // allowed to type 2 digits, so this will only add zeros (unless
    // we have bad data to begin with i.e "400.235")
    const goal = Number(newValues.fundraiserGoal).toFixed(2);

    // We can assert that campaign is defined when event is a fundraiser.
    newPayload.campaign = {
      id: oldValues.campaign?.id,
      goal: String(goal),
    };
  }

  return newPayload as Event;
};

export const encodeStringForCloudinary = (str: string) => {
  // Not super pumped about this encoder, but this is how we do it in dreambig
  // and we need to mimic behavior in this case.
  return (
    encodeURI(str)
      .replace(/,/g, "%252C")
      .replace(/:/g, "%253A")
      .replace(/!/g, "%2521")
      .replace(/\?/g, "%253F")
      .replace(/@/g, "%2540")
      .replace(/#/g, "%2523")
      .replace(/\//g, "%252F")
      //  Remove emojis.
      .replace(
        /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
        ""
      )
  );
};
