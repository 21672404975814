import React, { FC } from "react";
import { ConfirmationModal } from "../../components/confirmationModal";
import { register } from "../../serviceWorker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  editPaymentStatus,
  selectPaymentDetail,
} from "../../models/payments/paymentDetailSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { selectApplicationDetail } from "../../models/applications/applicationDetailSlice";

interface Props {
  show: boolean;
  onClose: () => any;
}

export const ProcessingModal: FC<Props> = ({ show, onClose }) => {
  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onChange",
    defaultValues: { sendToBillCom: "No" },
  });
  const formValues = watch();
  const payment = useSelector(selectPaymentDetail);
  const { application } = useSelector(selectApplicationDetail);
  const org = useSelector(selectOrg);
  const dispatch = useDispatch();
  async function process({ sendToBillCom }: any) {
    const res: any = await dispatch(
      editPaymentStatus({
        id: payment?.id as number,
        status: "processing",
        sendToBillCom: sendToBillCom === "Yes" ? true : false,
      })
    );
    if (res.error) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message:
            "Error - Payment Status Update Failed. Please try again later.",
          type: "danger",
        })
      );
    } else {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Payment Status Has Been Updated!",
          type: "success",
        })
      );
    }
    onClose();
  }
  return (
    <ConfirmationModal
      show={show}
      title="Confirm Processing Status"
      onClose={onClose}
      cta={
        <button
          disabled={formState.isSubmitted}
          onClick={handleSubmit(process)}
          className="btn btn-primary"
        >
          Confirm Mark Processing
        </button>
      }
    >
      {org.meta?.benevolenceAdminArea?.sendToBillCom && (
        <div className="form-group">
          <label>
            Send this invoice and payment detail to{" "}
            <a href="https://www.bill.com">bill.com</a>
          </label>
          <div className="form-check">
            <input
              name="sendToBillCom"
              type="radio"
              value="Yes"
              ref={register({ required: false })}
            />
            <label className="form-check-label ml-1">Yes</label>
          </div>

          <div className="form-check">
            <input
              name="sendToBillCom"
              type="radio"
              value="No"
              ref={register({ required: false })}
            />
            <label className="form-check-label ml-1">No</label>
          </div>
        </div>
      )}
      <p>
        Clicking "Confirm Mark Processing" will display the approved payment as
        "Processing"
        {org.meta?.benevolenceAdminArea?.sendToBillCom &&
        formValues.sendToBillCom === "Yes"
          ? ", and the invoice and payment details will be sent to bill.com"
          : ""}
      </p>
    </ConfirmationModal>
  );
};
