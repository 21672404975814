import { MyUser } from "../models/users/userInterfaces";

export const isAdmin = (user: MyUser) => {
  const roles = user.roles;
  if (
    roles.benevolenceAdmin ||
    roles.benevolenceBillingAdmin ||
    roles.benevolenceCompanyAdmin ||
    roles.companyAdmin
  )
    return true;
  return false;
};
