import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { getApplicationTasks, getApplicationDetail } from "../applications/applicationDetailSlice";
import { PaymentAPIResponse, PaymentDetail } from "./paymentInterfaces";
import { generateImageUrl } from "../../utils/helpers";


const initialState = null

export const getPaymentDetail = createAsyncThunk<PaymentDetail, number>('paymentDetail/getPaymentDetail', async (id, thunkApi) => {
    const {data: payment} = await api.get<PaymentAPIResponse>(`/submissions/payments/${id}`)
    await Promise.all([
        thunkApi.dispatch(getApplicationTasks(payment.submission.id)),
        thunkApi.dispatch(getApplicationDetail(payment.submission.id))
    ])
    const result: PaymentDetail = {
        ...payment,
        applicationId: payment.submission.id,
        user: {
          ...payment.submission.user,
          userId: payment.submission.user.id,
          imgUrl: generateImageUrl(payment.submission.user)
        },
        organization: {
          ...payment.submission.organization,
          imgUrl: generateImageUrl(payment.submission.organization)
        }
      }

      return result
})


export const editPaymentStatus = createAsyncThunk<void, {id: number, status: string, sendToBillCom?: boolean}>(
    'paymentDetail/editPaymentStatus', async ({id, status, sendToBillCom}, thunkApi) => {
        const data: any = {status};
        if(sendToBillCom !== undefined) data.sendToBillCom = sendToBillCom;
        await api.patch(`/submissions/payments/${id}`, {submissionPayment: data})
        await thunkApi.dispatch(getPaymentDetail(id))
    }
)

const paymentDetailSLice = createSlice({
    name: "paymentDetail",
    reducers: {},
    initialState: initialState as PaymentDetail | null,
    extraReducers: {
        [getPaymentDetail.fulfilled.type]: (state, {payload}: PayloadAction<PaymentDetail>) => {
            return payload;
        }
    }
})



export const selectPaymentDetail = (state: RootState) => state.paymentDetail

export default paymentDetailSLice.reducer