import React, { FC, useCallback, useEffect, useState } from "react";

import "./application.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplications,
  selectApplications,
} from "../../models/applications/applicationsSlice";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { useRouteMatch, useHistory } from "react-router-dom";
import api from "../../utils/api";
import { selectOrg } from "../../models/organization/organizationSlice";
import {
  statusToTitle,
  formatMoney,
  formatDate,
  applicationStatusToTitle,
  asyncTimeout,
} from "../../utils/helpers";
import {
  Application,
  SortOptions,
} from "../../models/applications/applicationInterfaces";
import { TableColHeader } from "../../components/tableColHeader";
import { selectMyUser } from "../../models/users/myUserSlice";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { Spinner } from "../../components/spinner";
import Pagination from "../../components/pagination/pagination";
import { isAdmin } from "../../utils/isAdmin";
import Unauthorized from "../Unauthorized";

export const ApplicationDashboard = () => {
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  let history = useHistory();
  let [showSpinner, setSpinner] = useState(true);
  const organization = useSelector(selectOrg);
  const user = useSelector(selectMyUser);

  const headerCols = [
    { title: "ID", value: "id" },
    {
      title: organization.meta?.acquire ? "Assigned To" : "Client",
      value: organization.meta?.acquire ? "_assigned_to" : "client", // NOTE: _assigned_to is disabled while it is adjusted on WeServe in submissions_controller. When ready to enable, change to "assigned_to"
    },
    { title: "First Name", value: "first_name" },
    { title: "Last Name", value: "last_name" },
    {
      title: `${
        organization.meta?.acquire ? "Product Type" : "Category of Need"
      }`,
      value: "category",
    },
    { title: "Request Amount", value: "amount_requested" },
    { title: "Received", value: "created_at" },
    { title: "Last Activity", value: "last_activity" },
    { title: "Status", value: "status" },
  ];

  const fetchApplications = useCallback((sortOptions?: SortOptions) => {
    return dispatch(getApplications(sortOptions || {}));
  }, []);

  async function fetchDataOnLoad() {
    await fetchApplications({});
    await asyncTimeout(300);
    setSpinner(false);
  }
  useEffect(() => {
    fetchDataOnLoad();
  }, []);

  const [isLoading] = useRequestStatus(getApplications.typePrefix);
  const { submissions, totalRecords, options } =
    useSelector(selectApplications);
  const myUser = useSelector(selectMyUser);
  const [search, setSearch] = useState(options.search);
  let filter = "";

  if (options.assignedTo) filter = "assignedToMe";
  if (options.status) filter = options.status;
  if (options.includeArchived) filter = "includeArchived";
  function hasRecentActivity(sub: Application) {
    const lastViewed = sub.lastViewed ? new Date(sub.lastActivity) : null;
    const lastActivity = sub.lastActivity ? new Date(sub.lastActivity) : null;
    if (!lastActivity) return false;
    if (!lastViewed) return true;
    if (lastActivity.toUTCString() > lastViewed.toUTCString()) return true;
    return false;
  }

  const getAmountRequested = (sub: any) => {
    // If an amount requested is not provided, return dashes
    if (!sub) return "--------";
    const formattedRequest = formatMoney(sub.amountRequested);
    // If the amount requested is zero, return dashes
    if (formattedRequest === "$0.00") return "--------";
    // Else return the formatted amount
    return formattedRequest;
  };

  // Guard against non-admins viewing this page.
  if (user && !isAdmin(user)) return <Unauthorized userId={user.userId} />;

  return user ? (
    <div id="application-dashboard" className="container-fluid">
      {/* "View" Dropdown - filters dashboard */}
      <div className="application-table-control-container row justify-content-between">
        <div className="col-4">
          <div className="form-group row control-filter align-items-center">
            <label className="form-check-label px-3">View</label>
            <select
              className="custom-select col"
              value={filter}
              onChange={(e) => {
                if (!e.target.value) {
                  return fetchApplications({
                    assignedTo: undefined,
                    status: undefined,
                    includeArchived: false,
                    offset: 0,
                  });
                }
                if (e.target.value === "assignedToMe") {
                  return fetchApplications({
                    assignedTo: myUser?.userId,
                    status: undefined,
                    includeArchived: false,
                    offset: 0,
                  });
                }
                if (e.target.value === "includeArchived") {
                  return fetchApplications({
                    assignedTo: undefined,
                    status: undefined,
                    includeArchived: true,
                    offset: 0,
                  });
                }
                return fetchApplications({
                  assignedTo: undefined,
                  status: e.target.value,
                  includeArchived: false,
                  offset: 0,
                });
              }}
            >
              <option value="">All</option>
              <option value="assignedToMe">Assigned to Me</option>
              {Object.keys(applicationStatusToTitle).map((status) => {
                return (
                  <option value={status} key={status}>
                    {applicationStatusToTitle[status]}
                  </option>
                );
              })}
              <option value="includeArchived">Include Archived</option>
            </select>
          </div>
        </div>
        {/* Search Bar */}
        <div className="col-4">
          <div className="d-flex justify-content-end">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  fetchApplications({ search: e.target.value, offset: 0 });
                }}
              />
            </div>
            {!myUser?.roles.benevolenceCompanyAdmin && (
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    await api.post(
                      `/reports/organizations/${organization.id}/submissions/download`
                    );
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message: ` Please Check ${myUser?.email} in the Next 15 minutes For your Exported File!`,
                        type: "success",
                      })
                    );
                  } catch (e) {
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message:
                          "Error - Applications Export Failed. Please try again later.",
                        type: "danger",
                      })
                    );
                  }
                }}
              >
                Export CSV
              </button>
            )}
          </div>
        </div>
      </div>
      <GlobalFlashMessage />
      <div
        className="application-table"
        style={{ position: "relative", minHeight: 600 }}
      >
        <Spinner show={isLoading} />

        {/* Dashboard Table */}
        {submissions.length > 0 ? (
          <>
            <table className="table table-borderless table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th scope="col"></th>
                  {headerCols.map((h) => (
                    <TableColHeader
                      title={h.title}
                      key={h.value}
                      sort={
                        options.sort === h.value ? options.direction : undefined
                      }
                      onClick={(direction: "asc" | "desc") => {
                        fetchApplications({
                          sort: h.value,
                          direction: direction,
                        });
                      }}
                    />
                  ))}

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((sub) => {
                  return (
                    <tr
                      key={sub.id}
                      onClick={() => {
                        history.push(`applications/${sub.id}/activity`);
                      }}
                    >
                      <td className="new-cta">
                        {hasRecentActivity(sub) && (
                          <span className="oi oi-media-record"></span>
                        )}
                      </td>
                      <td>{sub.id}</td>
                      {/* Display assigned admin in Acquire Mode and Organization Name in Benevolence Mode */}
                      <td>
                        {organization.meta?.acquire
                          ? sub.benevolenceAdmin.firstName +
                            " " +
                            sub.benevolenceAdmin.lastName
                          : sub.organization.name}
                      </td>
                      <td>{sub.user.firstName}</td>
                      <td>{sub.user.lastName}</td>
                      <td>{sub.category}</td>
                      <td>{getAmountRequested(sub)}</td>
                      <td>{formatDate(sub.createdAt)}</td>
                      <td>{formatDate(sub.lastActivity) || "N/A"}</td>
                      <td
                        onClick={() => {
                          history.push(`applications/${sub.id}/activity`);
                        }}
                      >
                        {sub.archived && (
                          <div
                            className="text-primary text-uppercase"
                            style={{ fontSize: "8px" }}
                          >
                            Archived
                          </div>
                        )}
                        <div>{statusToTitle[sub.status]}</div>
                      </td>{" "}
                      <td className="cta-arrow">
                        <span className="oi oi-chevron-right"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Footer */}
            <div className="d-flex justify-content-between px-5 pt-3 align-items-center">
              <Pagination
                sortOptions={options}
                loading={isLoading}
                totalRecords={totalRecords}
                fetch={fetchApplications}
              />
            </div>
          </>
        ) : (
          <p className="d-block text-center mt-5 h5 font-italic text-muted">
            No entries found
          </p>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
