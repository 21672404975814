import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import organization from "../models/organization/organizationSlice";
import applicationStatus from "../models/applicationStatusSlice";
import applications from "../models/applications/applicationsSlice";
import asyncRequestStatus from "../models/asyncRequestStatusReducer";
import applicationDetail from "../models/applications/applicationDetailSlice";
import payments from "../models/payments/paymentsSlice";
import events from "../models/events/eventSlice";
import paymentDetail from "../models/payments/paymentDetailSlice";
import myUser from "../models/users/myUserSlice";
import systemMessage from "../models/systemMessageSlice";

export const store = configureStore({
  reducer: {
    organization,
    applicationStatus,
    applicationDetail,
    asyncRequestStatus,
    myUser,
    applications,
    payments,
    events,
    paymentDetail,
    systemMessage,
  },
});

export function getDispatch() {
  return store.dispatch;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
