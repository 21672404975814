import { Role, Roles } from "../models/users/userInterfaces";

export const hasRole = (userRoles: Roles, role: Role) => {
  return userRoles[role];
};

export const canAccess = (userRoles: Roles, roleArray: Role[]) => {
  // Iterate over roleArray, and return true if one of the roles comes back true.
  const intersection = roleArray.filter((roleKey) => userRoles[roleKey]);
  return !!intersection.length;
};
