import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { Payment, PaymentAPIResponse } from "./paymentInterfaces";
import { ListAPIResponse } from "../../utils/apiInterfaces";
import { generateImageUrl } from "../../utils/helpers";
import { SortOptions } from "../applications/applicationInterfaces";

const queryString = require("query-string");

interface PaymentsState {
  payments: Payment[];
  totalRecords: number;
  options: SortOptions & { offset: number };
}

const initialState: PaymentsState = {
  payments: [],
  totalRecords: 0,
  options: { offset: 0, sort: "created_at", direction: "desc" },
};

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async (options: SortOptions, thunkApi): Promise<PaymentsState> => {
    const { organization, payments }: RootState = thunkApi.getState() as any;
    const newOptions = { ...payments.options, ...options };
    const query = queryString.stringify({
      ...newOptions,
      limit: 10,
      benevolenceOrganizationId: organization.id,
    });
    const { data } = await api.get<ListAPIResponse<PaymentAPIResponse>>(
      `/submissions/payments?${query}`
    );
    const results: Payment[] = data.records.map((payment) => {
      return {
        ...payment,
        applicationId: payment.submission.id,
        user: {
          ...payment.submission.user,
          userId: payment.submission.user.id,
          imgUrl: generateImageUrl(payment.submission.user),
        },
        organization: {
          ...payment.submission.organization,

          imgUrl: generateImageUrl(payment.submission.organization),
        },
      };
    });
    return {
      payments: results,
      totalRecords: data.totalRecords,
      options: newOptions,
    };
  }
);

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: {
    [getPayments.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PaymentsState>
    ) => {
      return payload;
    },
  },
});

export const selectPayments = (state: RootState) => state.payments;

export default paymentsSlice.reducer;
