import React, { FC } from "react";
import { Task } from "../../models/applications/applicationInterfaces";
import { SecondaryNavbar } from "../../components/secondaryNavBar";
import { mobileCheck } from "../../utils/helpers";
interface Props {
  isMyApplication: boolean;
  tasks: Task[];
}
export const ApplicationNavbar: FC<Props> = ({ isMyApplication, tasks }) => {
  const completedTasks = tasks.filter((t) => t.status === "complete");
  const isMobile = mobileCheck();

  // If the application owner (normal user) views, then show documents first.
  // If an admin views, show activity first.
  const navItems = isMyApplication
    ? // User View
      [
        {
          title: isMobile
            ? "Documents"
            : `Documents (${completedTasks.length} of ${tasks.length})`,
          path: "documents",
        },
        { title: "Activity", path: "activity" },
      ]
    : // Admin View
      [
        { title: "Activity", path: "activity" },
        {
          title: isMobile
            ? "Documents"
            : `Documents (${completedTasks.length} of ${tasks.length})`,
          path: "documents",
        },
        { title: "Messages", path: "messages" },
        { title: "Notes", path: "notes" },
      ];

  return <SecondaryNavbar navItems={navItems} />;
};
