import React, { FC, useState, useEffect } from "react";
import {
  EventStatus,
  ApplicationDetail,
} from "../../models/applications/applicationInterfaces";
import { SidebarApplicationDetail } from "./sidebarApplicationDetail";
import { statusToTitle, formatDate, formatMoney } from "../../utils/helpers";
import { ApprovalModal } from "../../pages/applications/approvalModal";
import DocSwapModal from "../../pages/applications/docSwapModal";
import { DenyModal } from "../../pages/applications/denyModal";
import { ProcessingModal } from "../../pages/payments/processingModal";
import { PaidModal } from "../../pages/payments/paidModal";
import { ReviewModal } from "../../pages/applications/reviewModal";
import { useSelector, useDispatch } from "react-redux";
import { selectMyUser } from "../../models/users/myUserSlice";
import { reject } from "lodash";
import { selectPaymentDetail } from "../../models/payments/paymentDetailSlice";
import api from "../../utils/api";
import { changeAssignedAdmin } from "../../models/applications/applicationDetailSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import {
  approveApplication,
  getApplicationDetail,
} from "../../models/applications/applicationDetailSlice";
import {
  FileEarmarkTextFill,
  ArrowRepeat,
  ListTask,
  BriefcaseFill,
  CheckCircleFill,
  FolderFill,
  PersonCheckFill,
  XOctagonFill,
  Archive,
  ArchiveFill,
} from "react-bootstrap-icons";

interface Props {
  application: ApplicationDetail;
  statusToView: "APPLICATION" | "PAYMENT";
  status: string;
  showReassign: boolean;
  retrieveData?: Function;
  tasks?: any;
}

interface StatusChangeAction {
  status: string;
  onClick: () => Promise<any>;
}
export const AdminSidebar: FC<Props> = ({
  application,
  statusToView,
  status,
  showReassign,
  retrieveData,
  tasks,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [requestedAction, setRequestedAction] = useState(null as null | string);
  const myUser = useSelector(selectMyUser);
  const dispatch = useDispatch();
  const payment = useSelector(selectPaymentDetail);
  const [admins, setAdmins] = useState([]);
  const organization = useSelector(selectOrg);
  const orgTaskTemplates = organization.orgTaskTemplates
    ? Object.entries(organization.orgTaskTemplates)
    : [];

  // Assets for SwapDoc functionality
  const [docSwapModalVisibility, setDocSwapModalVisibility] = useState(false);
  const [docSwapSelection, setDocSwapSelection] = useState(0);
  const swapDoc = async () => {
    try {
      await api.post(`/submissions/${application.id}/tasks/swap`, {
        submissionTaskTemplateSlug: orgTaskTemplates[docSwapSelection][0],
      });
      setDocSwapModalVisibility(false);
      retrieveData && retrieveData();
    } catch (e) {
      console.error(e);
    }
  };

  // Assets for Archival functionality
  const [archiveLoading, setArchiveLoading] = useState(false);
  const archive = async () => {
    try {
      setArchiveLoading(true);
      await api.post(`/submissions/${application.id}/archive`);
      await dispatch(getApplicationDetail(application.id));
    } catch (e) {
      console.error(e);
    }
    setArchiveLoading(false);
  };
  const unarchive = async () => {
    try {
      setArchiveLoading(true);
      await api.post(`/submissions/${application.id}/unarchive`);
      await dispatch(getApplicationDetail(application.id));
    } catch (e) {
      console.error(e);
    }
    setArchiveLoading(false);
  };

  useEffect(() => {
    // Retrieve all benevolence admins for the benevolence org associated to the application.
    api
      .get(
        `/organizations/${application.benevolenceOrganization.id}/benevolence-admins`
      )
      .then(({ data }) => {
        setAdmins(data.records);
      });
  }, []);

  // For Acquire mode, when the reqested action is updated to "approved", run an "approve" api call.
  useEffect(() => {
    if (requestedAction === "approved" && organization.meta?.acquire) {
      approve({
        amountApproved: 0,
        amountCampaign: 0,
        amountMatch: 0,
        title: `BoA Approval - Application: ${application.id}`,
        description: "An approval generated for BoA",
      });
    }
  }, [requestedAction]);

  // For Acquire mode, this is the function that is run to approve an application in the useEffect above.
  async function approve(data: any) {
    const res: any = await dispatch(
      approveApplication({
        id: application?.id as number,
        payload: data,
      })
    );
    if (res.error) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message:
            "Error - Application Status Update Failed. Please try again later.",
          type: "danger",
        })
      );
    } else {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Application Status Has Been Updated!",
          type: "success",
        })
      );
    }
  }

  // Convert a raw template name from JSON into something readable by the user.
  // EXAMPLE: "acquire_tasks__nutmeg_auto_loan" is displayed as "Acquire Tasks Nutmeg Auto Loan"
  const formatTemplateName = (key: string) => {
    const formattedName = key
      .toLowerCase() // make lowercase
      .split("_") // create array of all words
      .map((word) => {
        // capitalize first letter of all word
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" "); // join array words back into one string
    return formattedName.trim(); // Return the final name with all white space in the front removed.
  };

  // Renders an icon to go with the corresponding status
  const renderStatusIcon = (status: string) => {
    switch (status) {
      case "approved":
        return <CheckCircleFill />;
      case "denied":
        return <XOctagonFill />;
      case "ready_for_partner_review":
        return <BriefcaseFill />;
      case "ready_for_client_review":
        return <PersonCheckFill />;
      case "items_needed":
        return <FolderFill />;
      default:
        return "";
    }
  };

  function getAvailableStatusChanges(): StatusChangeAction[] {
    if (statusToView === "PAYMENT") {
      return [
        {
          status: "processing",
          onClick: async () => {
            setRequestedAction("processing");
            setShowConfirmationModal(true);
          },
        },
        {
          status: "paid",
          onClick: async () => {
            setRequestedAction("paid");
            setShowConfirmationModal(true);
          },
        },
      ];
    }

    // LoDash Reject Method (collection, [preidcate=_.identity])
    return reject(
      [
        // Define a list of possible actions (a collection)
        {
          status: "approved",
          onClick: async () => {
            setRequestedAction("approved");
            setShowConfirmationModal(true);
          },
        },
        {
          status: "ready_for_client_review",
          onClick: async () => {
            setRequestedAction("ready_for_client_review");
            setShowConfirmationModal(true);
          },
        },
        {
          status: "denied",
          onClick: async () => {
            setRequestedAction("denied");
            setShowConfirmationModal(true);
          },
        },
      ],
      // Iterate through each possible actionsto generate the list of available actions to show the user.
      // All items will be displayed EXCEPT the one that the below condition returns truthy for.
      (action) =>
        action.status === "ready_for_client_review" &&
        !application.organization.meta.benevolenceAdminArea.requireClientReview
    );
  }

  function allowedToEdit(requestedStatus: string) {
    if (statusToView === "PAYMENT") {
      if (payment?.status === "paid") return false;
      return myUser?.roles.benevolenceBillingAdmin;
    }

    if (statusToView === "APPLICATION") {
      const currentApplicationStatus = application.status;
      if (
        !myUser?.roles.benevolenceCompanyAdmin &&
        !myUser?.roles.benevolenceAdmin
      )
        return false;
      if (currentApplicationStatus === "approved") return false;
      if (currentApplicationStatus === "denied") return false;

      if (
        currentApplicationStatus === "items_needed" &&
        requestedStatus !== "denied"
      )
        return false;
      // if(currentApplicationStatus === "ready_for_client_review" && (requestedStatus === 'denied' || requestedStatus === 'approved')) {
      //   return myUser?.roles.benevolenceCompanyAdmin
      // }
      switch (requestedStatus) {
        case "approved":
          if (
            application.organization.meta.benevolenceAdminArea
              .requireClientReview &&
            myUser?.roles.benevolenceAdmin
          )
            return false;
        case "denied":
          if (myUser?.roles.benevolenceAdmin) return true;
          if (
            myUser.roles.benevolenceCompanyAdmin &&
            currentApplicationStatus === "ready_for_client_review"
          )
            return true;
          return false;
        default:
          return myUser?.roles.benevolenceAdmin;
      }
    }
  }

  // When an action is selected, render a modal that asks for confirmation.
  function renderConfirmationModal() {
    if (!showConfirmationModal || !requestedAction) return <></>;
    switch (requestedAction) {
      case "approved":
        // If configured for Acquire BoA, instantly approve the application without a modal (see the useEffect above)
        // * Instead, the application will be approved in the useEffect above.
        if (organization.meta?.acquire) {
          return <></>;
        }
        return (
          <ApprovalModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
          />
        );
      case "denied":
        return (
          <DenyModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
          />
        );
      case "processing":
        return (
          <ProcessingModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
          />
        );
      case "paid":
        return (
          <PaidModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
          />
        );
      case "ready_for_client_review":
        return (
          <ReviewModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
          />
        );
    }
  }

  // --------------------
  // RENDER
  // --------------------
  return (
    <>
      {/* Document Swap Modal - Only shows when triggered by parent component */}
      <DocSwapModal
        show={docSwapModalVisibility}
        swapDoc={swapDoc}
        onClose={() => setDocSwapModalVisibility(false)}
        currentTasks={tasks}
        orgTaskTemplates={orgTaskTemplates}
        docSwapSelection={docSwapSelection}
      />

      {/* MAIN CONTENT -------------------------------------------------------- */}
      <div className="detail-sidebar d-flex flex-column">
        <div className="sidebar-section">
          {/* Application Status */}
          <div className="sidebar-status d-flex flex-column bg-primary mt-4 px-3 py-2">
            <h2 className="mb-0" style={{ fontWeight: 800 }}>
              {application.user.firstName} {application.user.lastName}
            </h2>
            <span className="h6">
              {application.status === "approved"
                ? "APPLICATION COMPLETE"
                : "APPLICATION IN PROGRESS"}
            </span>
          </div>
        </div>
        {/* Spacing Div because of absolute positioning on above element */}
        <div style={{ marginBottom: "110px" }} />

        {/* Benevolance Logo - Only in Benevolence  */}
        {!organization.meta?.acquire && (
          <div className="pt-4 px-4">
            <div className="sidebar-logo-container">
              <img
                src={application.organization.imgUrl}
                width={225}
                className="mx-auto"
              />
            </div>
          </div>
        )}
        <div className="sidebar-section pl-4">
          {/* Assignment Button */}
          {showReassign && (
            <>
              <span className="font-weight-bold"> Assigned Team Member:</span>
              {!myUser?.roles.benevolenceAdmin && (
                <p>
                  {application.benevolenceAdmin.firstName}{" "}
                  {application.benevolenceAdmin.lastName}
                </p>
              )}
              {myUser?.roles.benevolenceAdmin && (
                <select
                  className="custom-select mb-3"
                  style={{ maxWidth: "95%" }}
                  value={application.benevolenceAdmin.id}
                  onChange={async (e) => {
                    const adminId = e.target.value;

                    const res: any = await dispatch(
                      changeAssignedAdmin(+adminId)
                    );
                    if (res.error) {
                      dispatch(
                        systemMessageSlice.actions.setMessage({
                          message:
                            "Error - Reassign Team Member Failed. Please try again.",
                          type: "danger",
                        })
                      );
                    } else {
                      dispatch(
                        systemMessageSlice.actions.setMessage({
                          message: "Reassign Team Member Successfully!",
                          type: "success",
                        })
                      );
                    }
                  }}
                >
                  {admins.map(
                    (user: {
                      id: number;
                      firstName: string;
                      lastName: string;
                    }) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    }
                  )}
                </select>
              )}
            </>
          )}

          {/* Status Button */}
          <span className="font-weight-bold">Status:</span>
          <div style={{ fontSize: "18px" }} className="mb-2">
            {renderStatusIcon(status)}{" "}
            <span className="font-italic">{statusToTitle[status]}</span>
          </div>

          <div className="dropdown">
            <a
              className="btn btn-primary btn-block d-flex align-items-center"
              style={{ maxWidth: "95%" }}
              href="javascript:void(0);"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="text-left flex-fill">
                <ListTask className="ml-2 mr-4" style={{ fontSize: "30px" }} />
                Update Status
              </div>
              <span className="oi oi-caret-bottom"></span>
            </a>

            <div
              className="dropdown-menu border-highlight"
              aria-labelledby="dropdownMenuLink"
            >
              {getAvailableStatusChanges().map((action) => {
                return (
                  <button
                    className="dropdown-item"
                    disabled={
                      status === action.status || !allowedToEdit(action.status)
                    }
                    onClick={action.onClick}
                  >
                    <span
                      className="mr-3 text-secondary"
                      style={{ fontSize: "24px" }}
                    >
                      {renderStatusIcon(action.status)}
                    </span>
                    {statusToTitle[action.status]}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Document Swap Button */}
          {organization.meta?.acquire &&
            myUser?.roles.benevolenceAdmin &&
            orgTaskTemplates.length > 1 && (
              <>
                <div className="dropdown mt-2">
                  <a
                    className="btn btn-primary btn-block d-flex align-items-center"
                    href="javascript:void(0);"
                    style={{ maxWidth: "95%" }}
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-left flex-fill">
                      <ArrowRepeat
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Swap Documents
                    </div>

                    <span className="oi oi-caret-bottom"></span>
                  </a>
                  <div
                    className="dropdown-menu border-highlight"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {orgTaskTemplates.map((template, key) => {
                      return (
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            setDocSwapModalVisibility(true);
                            setDocSwapSelection(key);
                          }}
                        >
                          <FileEarmarkTextFill
                            className="mr-3 text-secondary"
                            style={{ fontSize: "24px" }}
                          />
                          {formatTemplateName(template[0])}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

          {/* Archive/Unarchive Button */}
          {myUser?.roles.benevolenceAdmin && (
            <div className="mt-2">
              <button
                className="btn btn-primary btn-block d-flex align-items-center"
                style={{ maxWidth: "95%" }}
                onClick={() => (application.archived ? unarchive() : archive())}
              >
                {/* Upon clicking button, instantly update displayed button while 
                the updated application details are fetched. */}
                {application.archived ? (
                  !archiveLoading ? (
                    <div>
                      <ArchiveFill
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Unarchive
                    </div>
                  ) : (
                    <div>
                      <Archive
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Archive
                    </div>
                  )
                ) : !archiveLoading ? (
                  <div>
                    <Archive
                      className="ml-2 mr-4"
                      style={{ fontSize: "30px" }}
                    />
                    Archive
                  </div>
                ) : (
                  <div>
                    <ArchiveFill
                      className="ml-2 mr-4"
                      style={{ fontSize: "30px" }}
                    />
                    Unarchive
                  </div>
                )}
              </button>
            </div>
          )}
        </div>

        {application.event && !application.event.custom?.meta.fullyFunded && (
          <div className="sidebar-section px-4">
            <div className="sidebar-sub-section mt-4">
              <h6 className="h3 mb-0 text-center text-lg-left">
                Campaign Details
              </h6>
              <small className="text-primary text-uppercase">
                <span className="font-weight-bold">Status:</span>
                {application.event.status}
              </small>
            </div>
            <div>
              <span className="meta">Campaign Goal</span>
              <br />
              <span>{formatMoney(application.amountCampaign)}</span>
            </div>

            <div className="sidebar-sub-section mt-2">
              <span className="meta">Campaign Match</span>
              <br />
              <span>{formatMoney(application.amountMatch)}</span>
            </div>
            <div className="sidebar-sub-section">
              <span className="meta">Campaign Title</span>
              <br />
              <span>{application.event.title}</span>
            </div>
            {application.event.status !==
              EventStatus.PENDING_APPLICATION_APPROVAL && (
              <a
                href={`/events/${application.event.id}/detail`}
                target="_blank"
                className="btn btn-primary btn-block"
              >
                View Campaign
              </a>
            )}
          </div>
        )}
        <div className="sidebar-section">
          {!organization?.meta?.acquire && (
            <div className="sidebar-sub-section px-4 mt-3 text-primary">
              <small className="d-block">
                <span className="font-weight-bold text-uppercase">
                  Last Application Date:{" "}
                </span>
                <span className="font-italic">
                  {formatDate(application.lastSubmission || "") || "N/A"}
                </span>
              </small>
              <small className="text-primary">
                {
                  application.organization.meta.benevolenceAdminArea
                    .applicationTimeLimit
                }
              </small>
            </div>
          )}
          <SidebarApplicationDetail application={application} />
        </div>
        {renderConfirmationModal()}
      </div>
    </>
  );
};
