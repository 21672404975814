import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { EventOverview } from "./eventInterfaces";
import { ListAPIResponse } from "../../utils/apiInterfaces";
import { SortOptions } from "../applications/applicationInterfaces";

const queryString = require("query-string");

interface EventState {
  events: EventOverview[];
  totalRecords: number;
  options: SortOptions & { offset: number };
}

const initialState: EventState = {
  events: [],
  totalRecords: 0,
  options: { offset: 0, sort: "created_at", direction: "desc" },
};

interface GetEventsProps {
  id: number;
  sortOptions: SortOptions;
}

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (
    { id, sortOptions }: GetEventsProps,
    thunkApi
  ): Promise<EventState> => {
    const { events }: RootState = thunkApi.getState() as any;
    const newOptions = { ...events.options, ...sortOptions };
    const query = queryString.stringify({
      ...newOptions,
      limit: 10,
    });
    const { data } = await api.get<ListAPIResponse<EventOverview>>(
      `organizations/${id}/event-overview?${query}`
    );
    const results: EventOverview[] = data.records;

    return {
      events: results,
      totalRecords: data.totalRecords,
      options: newOptions,
    };
  }
);

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: {
    [getEvents.fulfilled.type]: (
      state,
      { payload }: PayloadAction<EventState>
    ) => {
      return payload;
    },
  },
});

export const selectEvents = (state: RootState) => state.events;

export default eventSlice.reducer;
