import React, { FC } from "react";
import { ConfirmationModal } from "../../components/confirmationModal";
import { register } from "../../serviceWorker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  editPaymentStatus,
  selectPaymentDetail,
} from "../../models/payments/paymentDetailSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";

interface Props {
  show: boolean;
  onClose: () => any;
}

export const PaidModal: FC<Props> = ({ show, onClose }) => {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const payment = useSelector(selectPaymentDetail);
  const org = useSelector(selectOrg);
  const dispatch = useDispatch();
  async function process({ sendToBillCom }: any) {
    const res: any = await dispatch(
      editPaymentStatus({
        id: payment?.id as number,
        status: "paid"
      })
      
    );
    if(res.error) {
      dispatch(systemMessageSlice.actions.setMessage({message: "Error - Payment Status Update Failed. Please try again later.", type: "danger"}))
    } else {
      dispatch(systemMessageSlice.actions.setMessage({message: "Payment Status Has Been Updated!", type: "success"}))
    }
    onClose();
  }
  return (
    <ConfirmationModal
      show={show}
      title="Confirm Paid Status"
      onClose={onClose}
      cta={
        <button
          disabled={formState.isSubmitted}
          onClick={handleSubmit(process)}
          className="btn btn-primary"
        >
          Confirm Mark Paid
        </button>
      }
    >
    
      <p>
        Clicking "Confirm Mark Paid" will display the approved payment as
        "Paid". The actual payment will need to be made on your grant payment platform.
      </p>
    </ConfirmationModal>
  );
};
