import React, { FC, useEffect, useState } from "react";
import { useParams, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";
import { Activity } from "./activity";
import { Notes } from "./notes";
import { Messages } from "./messages";
import { useDispatch, useSelector } from "react-redux";
import {
  selectApplicationDetail,
  getApplicationDetail,
  getApplicationActivity,
  getApplicationTasks,
  getApplicationNotes,
  getApplicationMessages,
} from "../../models/applications/applicationDetailSlice";
import { ApplicantSidebar } from "../../components/sidebar/applicantSidebar";
import { AdminSidebar } from "../../components/sidebar/adminSidebar";
import { selectMyUser } from "../../models/users/myUserSlice";
import { ApplicationNavbar } from "./applicationNavbar";
import { TaskViewer } from "../../components/sidebar/taskViewer";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { Spinner } from "../../components/spinner";
import { asyncTimeout } from "../../utils/helpers";
import { mixpanel } from "../../utils/mixpanel";

export const ApplicationDetail: FC = () => {
  const { applicationId } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { activity, application, tasks, notes, messages } = useSelector(
    selectApplicationDetail
  );
  const user = useSelector(selectMyUser);
  const [showSpinner, setShowSpinner] = useState(false);

  // State for managing the last selected note of child component "Notes" to improve the user experience when switching between tabs.
  const [activeNote, setActiveNote] = useState(0);

  // useEffect(() => {
  //   if (applicationId !== application?.id) {
  //     setShowSpinner(true);
  //   } else {
  //     setShowSpinner(false);
  //   }
  // }, [application?.id]);

  let isMyApplication: boolean | undefined = undefined;
  let canEdit = false;
  if (user && application) {
    isMyApplication = user?.userId === application?.user.userId;
    canEdit = isMyApplication || user.roles.benevolenceAdmin;
  }
  async function retrieveData() {
    await Promise.all([
      dispatch(getApplicationDetail(applicationId)),
      dispatch(getApplicationActivity(applicationId)),
      dispatch(getApplicationTasks(applicationId)),
      dispatch(getApplicationMessages(applicationId)),
      dispatch(getApplicationNotes({ id: applicationId })),
    ]);
    await asyncTimeout(300);
    setShowSpinner(false);
  }

  useEffect(() => {
    if (isMyApplication) {
      mixpanel.track("Application Detail - Application View", {
        "User Id": user?.userId,
        "Company Name": application?.organization.name,
        "Benevolence Partner Name": application?.benevolenceOrganization.name,
        "Host Name": window.location.hostname,
        Page: window.location.href,
      });
    }
  }, [isMyApplication]);

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <div id="application-detail" className="row no-gutters">
      {/* CONTENT -------------------------------------------------------------------- */}
      <Spinner show={showSpinner} />
      {application &&
        activity &&
        tasks &&
        isMyApplication !== undefined &&
        !showSpinner && (
          <>
            <div className="col-md-12 col-lg-9 order-md-1 order-lg-2">
              {/* Header Image */}
              <img
                style={{
                  height: "180px",
                  objectFit: "cover",
                  objectPosition: "0 42%",
                }}
                className="w-100"
                src={
                  isMyApplication
                    ? "https://res.cloudinary.com/cotribute/image/upload/v1657939033/client-admin/header.jpg"
                    : "https://res.cloudinary.com/cotribute/image/upload/v1658432735/client-admin/header-admin.jpg"
                }
              />
              {/* Tabs - "Items Needed From You" (etc) */}
              <ApplicationNavbar
                isMyApplication={isMyApplication}
                tasks={tasks}
              />
              <GlobalFlashMessage />
              {/* Main Content */}
              <div className="px-3">
                <Switch>
                  <PrivateRoute path={`${path}/documents`}>
                    {/* Documents Dashboard */}
                    <div className="documents-container p-5 mb-3 mb-3 shadow-left">
                      {tasks.map((task, i) => (
                        <div className="mb-5" key={task.id}>
                          <TaskViewer
                            task={task}
                            taskNumber={i + 1}
                            canEdit={
                              canEdit && application.status !== "approved"
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/activity`}>
                    <Activity activity={activity} application={application} />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/notes`}>
                    <Notes
                      applicant={application?.user}
                      isAdmin={user?.roles?.benevolenceAdmin || false}
                      application={application}
                      notes={notes}
                      activeNote={activeNote}
                      setActiveNote={setActiveNote}
                    />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/messages`}>
                    <Messages
                      isAdmin={user?.roles?.benevolenceAdmin || false}
                      application={application}
                      applicant={application?.user}
                      messages={messages}
                    />
                  </PrivateRoute>
                </Switch>
              </div>
            </div>

            <div className="col-md-12 col-lg-3 order-md-2 order-lg-1">
              {isMyApplication ? (
                <ApplicantSidebar application={application} />
              ) : (
                <AdminSidebar
                  application={application}
                  statusToView="APPLICATION"
                  status={application.status}
                  showReassign={true}
                  tasks={tasks}
                  retrieveData={retrieveData}
                />
              )}
            </div>
          </>
        )}
    </div>
  );
};
