import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentDetail,
  selectPaymentDetail,
} from "../../models/payments/paymentDetailSlice";
import { useRouteMatch, useParams, Switch } from "react-router-dom";
import {
  selectApplicationDetail,
  getApplicationDetail,
} from "../../models/applications/applicationDetailSlice";
import { AdminSidebar } from "../../components/sidebar/adminSidebar";
import { ApplicationNavbar } from "../applications/applicationNavbar";
import { PrivateRoute } from "../../components/PrivateRoute";
import { Activity } from "../applications/activity";
import { SecondaryNavbar } from "../../components/secondaryNavBar";
import { paymentType, formatMoney, asyncTimeout } from "../../utils/helpers";
import { File } from "../../components/file";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { Spinner } from "../../components/spinner";
import { download } from "../../utils/download";
import { systemMessageSlice } from "../../models/systemMessageSlice"

export const PaymentDetail: FC = () => {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { paymentId } = useParams();
  const { application, tasks } = useSelector(selectApplicationDetail);
  const payment = useSelector(selectPaymentDetail);

  const [showSpinner, setShowSpinner] = useState(true);

  async function retrieveDataOnLoad() {
    await dispatch(getPaymentDetail(paymentId));
    await asyncTimeout(300);
    setShowSpinner(false);
  }

  async function downloadFile(id: number, submissionTaskId: number) {
    await download(id, submissionTaskId, (error: string) => {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: error,
          type: "danger",
        })
      );
    });
  }
 
  useEffect(() => {
    retrieveDataOnLoad();
  }, []);
  return (
    <div id="application-detail" className="row no-gutters">
      <Spinner show={showSpinner} />
      {payment && application && tasks && !showSpinner && (
        <>
          <div className="col-md-12 col-lg-9 order-md-1 order-lg-2">
            {/* Header Image */}
            <img
              style={{
                height: "180px",
                objectFit: "cover",
                objectPosition: "0 42%",
              }}
              className="w-100"
              src={
                "https://res.cloudinary.com/cotribute/image/upload/v1657939033/client-admin/header.jpg"
              }
            />
            <SecondaryNavbar navItems={[{ title: "Payment", path: "" }]} />
            <GlobalFlashMessage />
            <div className="px-5 py-2">
              <h3 className="text-primary">Payment Details</h3>

              {/* Content Container */}
              <div className="row p-5 content-container">
                {/* Left Column: Full-width on Mobile */}
                <div className="col-12 col-lg-6 border-left border-primary">
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">
                      To Whom Should the Payment Be Made Out?
                    </p>
                    <span className="text-secondary">
                      {payment.recipientName}
                    </span>
                  </div>
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">
                      What is the address for payment?
                    </p>
                    <span className="text-secondary">
                      {payment.recipientAddress}
                    </span>
                  </div>
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">
                      Note to include with payment
                    </p>
                    <span className="text-secondary">
                      {payment.notes || "N/A"}
                    </span>
                  </div>
                </div>

                {/* Right Column: Full-width on Mobile */}
                <div className="col-12 col-lg-6 border-left border-primary">
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">Type</p>
                    <span className="text-secondary">
                      {paymentType[payment.classification]}
                    </span>
                  </div>
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">Approved Payout</p>
                    <span className="text-secondary">
                      {formatMoney(payment.amountApproved)}
                    </span>
                  </div>
                  <div className="mb-3">
                    <p className="h5 font-weight-bold mb-0">
                      Location of Funds
                    </p>
                    <span className="text-secondary">
                      {payment.fundLocation}
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  {tasks
                    .filter((t) => t.isBill)
                    .map((task) => {
                      return (
                        <div key={task.id}>
                          {task.articles.map((article) => {
                            return (
                              <div style={{ maxWidth: 350 }} className="mb-3">
                                <File 
                                  onDownload={() => downloadFile(article.id, task.id)} 
                                  canEdit={false} 
                                  article={article}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-3 order-md-2 order-lg-1">
            <AdminSidebar
              application={application}
              statusToView="PAYMENT"
              status={payment.status}
              showReassign={false}
            />
          </div>
        </>
      )}
    </div>
  );
};
