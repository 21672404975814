import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationAPIResponse,
  TasksAPIResponse,
  Task,
  Message,
  ActivityItem,
  ApplicationActivityAPIResponse,
  Questions,
  ApplicationDetail,
  Note,
  NotesAPIResponse,
} from "./applicationInterfaces";
import api from "../../utils/api";
import { RootState } from "../../app/store";
import { generateImageUrl } from "../../utils/helpers";

interface ApplicationDetailState {
  application: ApplicationDetail | undefined;
  activity: ActivityItem[];
  tasks: Task[];
  notes: Note[];
  messages: Message[];
}

const initialState: ApplicationDetailState = {
  activity: [],
  tasks: [],
  application: undefined,
  notes: [],
  messages: [],
};

// -------------- API METHODS CONNECTED TO APPLICATION --------------
export const getApplicationDetail = createAsyncThunk<ApplicationDetail, number>(
  "applicationDetail/getApplicationDetail",
  async (id, _thunkApi) => {
    const { data } = await api.get<ApplicationAPIResponse>(
      `/submissions/${id}`
    );
    const result: ApplicationDetail = {
      ...data,
      organization: {
        ...data.organization,
        meta: {
          benevolenceAdminArea: data.organization.metadata.benevolenceAdminArea,
        },
        imgUrl: generateImageUrl(data.organization),
      },
      benevolenceOrganization: {
        ...data.benevolenceOrganization,
        imgUrl: generateImageUrl(data.benevolenceOrganization),
      },
      user: {
        userId: data.user.id,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        imgUrl: generateImageUrl(data.user),
        email: data.user.email,
        phone: data.user.phone,
      },
    };

    return result;
  }
);

// *** METHODS FOR UPDATING STATUS OF APPILICATION ***
export const denyApplication = createAsyncThunk<
  void,
  { id: number; message: string }
>("applicationDetail/deny", async (payload, thunkApi) => {
  await api.patch(`submissions/${payload.id}/deny`, {
    message: payload.message,
  });
  await Promise.all([
    thunkApi.dispatch(getApplicationTasks(payload.id)),
    thunkApi.dispatch(getApplicationDetail(payload.id)),
    thunkApi.dispatch(getApplicationActivity(payload.id)),
  ]);
});

export const approveApplication = createAsyncThunk<
  void,
  { id: number; payload: any }
>("applicationDetail/approveApplication", async ({ id, payload }, thunkApi) => {
  await api.patch(`submissions/${id}/approve`, payload);
  await Promise.all([
    thunkApi.dispatch(getApplicationTasks(id)),
    thunkApi.dispatch(getApplicationDetail(id)),
    thunkApi.dispatch(getApplicationActivity(id)),
  ]);
});

export const readyForReview = createAsyncThunk<void, number>(
  "applicationDetail/readyForReview",
  async (id, thunkApi) => {
    await api.patch(`submissions/${id}/review`, {});
    await Promise.all([
      thunkApi.dispatch(getApplicationTasks(id)),
      thunkApi.dispatch(getApplicationDetail(id)),
      thunkApi.dispatch(getApplicationActivity(id)),
    ]);
  }
);

// *** CHANGE ASSIGNED ADMIN ***
export const changeAssignedAdmin = createAsyncThunk<void, number>(
  "applicationDetail/changeAssignedAdmin",
  async (adminId, thunkApi) => {
    const state: RootState = thunkApi.getState() as any;
    await api.patch(`submissions/${state.applicationDetail.application?.id}`, {
      benevolenceAdminId: adminId,
    });
    await thunkApi.dispatch(
      getApplicationDetail(state.applicationDetail.application?.id as number)
    );
  }
);

// *** TASK (REQUIRED DOCUMENT) METHODS ***
export const getApplicationTasks = createAsyncThunk<Task[], number>(
  "applicationDetail/getApplicationTasks",
  async (id, _thunkApi) => {
    const { data } = await api.get<TasksAPIResponse>(
      `/submissions/${id}/tasks`
    );
    return data.records.map((item) => {
      return {
        ...item,
        articles: item.articles.map((a) => {
          return {
            ...a,
            type: a.articleType.article_type,
          };
        }),
        questions: item.metadata.questions,
      };
    });
  }
);

export const updateTask = createAsyncThunk<
  Task,
  { questions?: Questions[]; articlesAttributes?: any[]; id: number }
>("applicationDetail/updateTask", async (task, thunkApi) => {
  const { applicationDetail }: RootState = thunkApi.getState() as any;
  const body: any = { submissionTask: {} };
  if (task.questions) {
    body.submissionTask.metadata = {
      questions: task.questions,
    };
  }
  if (task.articlesAttributes) {
    body.submissionTask.articlesAttributes = task.articlesAttributes;
  }
  const { data } = await api.patch<Task>(
    `submissions/${applicationDetail.application?.id}/tasks/${task.id}`,
    body
  );
  await Promise.all([
    thunkApi.dispatch(
      getApplicationTasks(applicationDetail.application?.id as number)
    ),
    thunkApi.dispatch(
      getApplicationDetail(applicationDetail.application?.id as number)
    ),
    thunkApi.dispatch(
      getApplicationActivity(applicationDetail.application?.id as number)
    ),
  ]);

  return data;
});

// *** POPULATE ACTIVITY LOG FROM PULSE TABLE ***
export const getApplicationActivity = createAsyncThunk<ActivityItem[], number>(
  "applicationDetail/getApplicationActivity",
  async (id, _thunkApi) => {
    const {
      data: { records },
    } = await api.get<ApplicationActivityAPIResponse>(
      `/submissions/${id}/feed?limit=1000`
    );
    return records.map((item) => {
      return {
        ...item,
        payload: {
          ...item.payload,
          message: item.payload.message?.description,
          paymentAmount: item.payload.submission_payment?.amount,
          denialMessage: item.payload.denial_message,
          campaignInformation: item.payload.campaign_information,
          notificationType: item.payload.notification_type,
        },
      };
    });
  }
);

// *** NOTES TAB METHODS *** //
export const getApplicationNotes = createAsyncThunk<Note[], any>(
  "applicationDetail/getApplicationNotes",
  async (params, _thunkApi) => {
    const { data } = await api.get<NotesAPIResponse>(
      `/submissions/${params.id}/notes?sort=updated_at&direction=desc&limit=100`
    );
    return data.records.map((note) => {
      return note;
    });
  }
);

export const addNote = createAsyncThunk<void, number>(
  "applicationDetail/addNote",
  async (id, thunkApi) => {
    await api.post(`/submissions/${id}/notes`, {
      body: "Add your note content here.",
    });
    await thunkApi.dispatch(getApplicationNotes({ id: id }));
  }
);

export const updateNote = createAsyncThunk<void, any>(
  "applicationDetail/updateNote",
  async (params, thunkApi) => {
    await api.patch(
      `/submissions/${params.applicationId}/notes/${params.noteId}`,
      { title: params.title, body: params.body }
    );
    await thunkApi.dispatch(getApplicationNotes({ id: params.applicationId }));
  }
);

export const deleteNote = createAsyncThunk<void, any>(
  "applicationDetail/deleteNote",
  async (params, thunkApi) => {
    await api.delete(
      `/submissions/${params.applicationId}/notes/${params.noteId}`
    );
    await thunkApi.dispatch(getApplicationNotes({ id: params.applicationId }));
  }
);
// *** MESSAGES TAB METHODS *** //
export const getApplicationMessages = createAsyncThunk<void, any>(
  "applicationDetail/getApplicationMessages",
  async (id, _thunkApi) => {
    const { data } = await api.get<any>(`/submissions/${id}/comments`);
    return data.records.map((message: any) => {
      return message;
    });
  }
);

// -------------- APPLICATION STATE MANAGEMENT --------------
// When the specified API call is fulfilled, update the application state with the returned data.
const applicationDetailSlice = createSlice({
  name: "applicationDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [getApplicationDetail.fulfilled.type]: (
      state,
      action: PayloadAction<ApplicationDetail>
    ) => {
      state.application = action.payload;
    },
    [getApplicationActivity.fulfilled.type]: (
      state,
      action: PayloadAction<ActivityItem[]>
    ) => {
      state.activity = action.payload;
    },
    [getApplicationTasks.fulfilled.type]: (
      state,
      action: PayloadAction<Task[]>
    ) => {
      state.tasks = action.payload;
    },
    [getApplicationNotes.fulfilled.type]: (
      state,
      action: PayloadAction<Note[]>
    ) => {
      state.notes = action.payload;
    },
    [getApplicationMessages.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.messages = action.payload;
    },
  },
});

export const selectApplicationDetail = (state: RootState) =>
  state.applicationDetail;

export default applicationDetailSlice.reducer;
