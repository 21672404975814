import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { IMG_BASE_URL } from "../../constants";
import {
  Application,
  ApplicationsAPIResponse,
  SortOptions,
} from "./applicationInterfaces";
import { generateImageUrl } from "../../utils/helpers";

const queryString = require("query-string");

export interface ApplicationsState {
  submissions: Application[];
  totalRecords: number;
  options: SortOptions & { offset: number };
}

const initialState: ApplicationsState = {
  submissions: [],
  totalRecords: 0,
  options: { offset: 0, sort: "created_at", direction: "desc" },
};

export const getApplications = createAsyncThunk<ApplicationsState, SortOptions>(
  "applications/getApplications",
  async (options, thunkApi) => {
    const state: any = (thunkApi as any).getState();
    const newOptions = { ...state.applications.options, ...options };
    const query = queryString.stringify({
      ...newOptions,
      limit: 10,
      benevolenceOrganizationId: state.organization.id,
    });
    const result = await api.get<ApplicationsAPIResponse>(
      `/submissions?${query}`
    );
    const response = result.data;
    const submissions: Application[] = response.records.map((sub) => {
      const application: Application = {
        ...sub,
        benevolenceAdmin: {
          id: sub.benevolenceAdmin.id,
          firstName: sub.benevolenceAdmin.firstName,
          lastName: sub.benevolenceAdmin.lastName,
        },
        organization: {
          ...sub.organization,
          id: sub.organization.id,
          name: sub.organization.name,
          imgUrl: generateImageUrl(sub.organization),
        },
        benevolenceOrganization: {
          id: sub.benevolenceOrganization.id,
          name: sub.benevolenceOrganization.name,
          imgUrl: generateImageUrl(sub.benevolenceOrganization),
        },
        user: {
          firstName: sub.user.firstName,
          lastName: sub.user.lastName,
          imgUrl: `${IMG_BASE_URL}/${sub.user.imageVersion}/${sub.user.imageId}`,
          userId: sub.user.id,
        },
      };
      return application;
    });

    return {
      options: newOptions,
      submissions: submissions,
      totalRecords: response.totalRecords,
    };
  }
);

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: {
    [getApplications.fulfilled.type]: (
      state,
      action: PayloadAction<ApplicationsState>
    ) => {
      return action.payload;
    },
  },
});

export const selectApplications = (state: RootState) => state.applications;

export default applicationsSlice.reducer;
