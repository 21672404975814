import { useRouteMatch, Switch } from "react-router-dom";
import React from "react";
import { PrivateRoute } from "../../components/PrivateRoute";
import { PaymentsDashboard } from "./dashboard";
import { PaymentDetail } from "./detail";

export const PaymentRouter = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <PaymentsDashboard />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:paymentId`}>
        <PaymentDetail />
      </PrivateRoute>
    </Switch>
  );
};
