import React, { FC } from "react";
import { useRouteMatch, useLocation, Link } from "react-router-dom";
import {
  Files,
  Activity,
  StickiesFill,
  CaretRightFill,
  Mailbox2,
} from "react-bootstrap-icons";
interface Props {
  navItems: { path: string; title: string }[];
}

export const SecondaryNavbar: FC<Props> = ({ navItems }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const renderIcon = (path: string) => {
    switch (path) {
      case "activity":
        return <Activity />;
      case "documents":
        return <Files />;
      case "messages":
        return <Mailbox2 />;
      case "notes":
        return <StickiesFill />;
      default:
        return <CaretRightFill />;
    }
  };

  return (
    <div className="application-navigation">
      {navItems.map((nav) => {
        return (
          <Link to={`${url}/${nav.path}`}>
            <div
              className={`nav-link ${
                pathname.includes(nav.path) ? "active" : ""
              }`}
              key={nav.title}
            >
              <div
                style={{ color: "white", display: "inline" }}
                className="mr-2"
              >
                {renderIcon(nav.path)}
              </div>
              <span className="font-weight-bold">{nav.title}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
