import React from "react";
import "./header.scss";
import Avatar from "../avatar";
import Navigation from "../navigation";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../models/users/myUserSlice";
import { MyUser } from "../../models/users/userInterfaces";
import { selectOrg } from "../../models/organization/organizationSlice";

interface Props {
  user?: MyUser | null;
  bannerImg: string;
}

const Header: React.FC<Props> = ({ user, bannerImg }) => {
  const dispatch = useDispatch();

  return (
    <nav
      className="d-flex justify-content-between bg-light pl-3"
      style={{ minHeight: 60, height: "7vh" }}
    >
      {/* <a className="navbar-brand" href="#"> */}
      <img
        src={bannerImg}
        height="50px"
        className="d-inline-block align-self-center"
        alt=""
      />
      {/* </a> */}
      {user && (
        <div className="ui">
          <Navigation />
          <div
            className={`dropdown user-avatar px-2 ${
              !user.roles.benevolenceAdmin && "bg-white"
            }`}
          >
            <div
              className="dropdown-toggle"
              id="avatar-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ cursor: "pointer" }}
            >
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                imgUrl={user.imgUrl}
              />
            </div>
            <div
              className="border-highlight dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              {/* <Link to={`${url}/profile`} className="dropdown-item">Profile</Link> */}

              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
