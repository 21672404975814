import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api from "../../utils/api";
import { IMG_BASE_URL } from "../../constants";
import {
  MyOrganization,
  OrganizationAPIResponse,
} from "./organizationInterfaces";

export interface OrgTaskTemplates {
  [key: string]: {
    key: string;
    tooltip_title: string;
    tooltip_description: string;
    link: string;
    document_required: boolean;
    is_bill: boolean;
    title: string;
    questions: {
      prompt: string;
      response: string;
      required: boolean;
    }[];
  }[];
}
export interface OrganizationState {
  loading: boolean;
  id?: number;
  slug?: string;
  imgUrl?: string;
  name?: string;
  groupId?: number;
  meta?: {
    acquire?: boolean; // If flagged true, will adjust features to work for Acquire Back Office Automation instead of a default Benevolance Campaign.
    sigmaUrl?: string; // This url can be found by opening a dashboard in sigma, clicking the center top title, and generating a public embed code.
    benevolenceAdminArea: {
      sendToBillCom: boolean;
    };
    clientFooterLogo?: string;
    clientPrivacyPolicy?: string;
  };
  orgTaskTemplates?: OrgTaskTemplates;
}

const initialState: OrganizationState = {
  loading: false,
};

export const getOrganizationBySlug = createAsyncThunk<MyOrganization, string>(
  "organization/getOrganizationBySlug",
  async (orgSlug: string) => {
    // Get initial organization data
    const {
      data: {
        organization: { logoId, logoVersion, id, meta },
        slug,
        title,
        group,
        ...rest
      },
    } = await api.get<OrganizationAPIResponse>(`/communities/${orgSlug}`);

    // Run second API call using org ID to get the submission task templates for that organization
    const orgTaskTemplates = await api.get(
      `organizations/${id}/submission-task-templates`
    );

    const result: MyOrganization = {
      ...rest,
      meta: meta,
      slug,
      id,
      imgUrl: `${IMG_BASE_URL}/${logoVersion}/${logoId}`,
      name: title,
      orgTaskTemplates: orgTaskTemplates.data,
    };
    return result;
  }
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrganizationBySlug.pending.type]: (state) => {
      state.loading = true;
    },
    [getOrganizationBySlug.fulfilled.type]: (
      state,
      { payload }: PayloadAction<MyOrganization>
    ) => {
      return { ...payload, loading: false };
    },
  },
});

export const selectOrg = (state: RootState) => state.organization;

export default organizationSlice.reducer;
