import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";
import "./login.scss";
import { Link, useHistory, useParams } from "react-router-dom";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { login, selectMyUser } from "../../models/users/myUserSlice";

interface Inputs {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [isLoading, loginError] = useRequestStatus(login.typePrefix);
  const myUser = useSelector(selectMyUser);
  const { register, errors, handleSubmit } = useForm<Inputs>({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const attemptLogin = async (data: { email: string; password: string }) => {
    data.email = data.email.toLowerCase().trim();
    const res: any = await dispatch(login(data));

    if (res.error || !res.payload) {
      return;
    }

    const {
      benevolenceAdmin,
      benevolenceBillingAdmin,
      benevolenceCompanyAdmin,
      companyAdmin,
    } = res.payload.roles;

    const isBenevolence =
      benevolenceAdmin || benevolenceBillingAdmin || benevolenceCompanyAdmin;

    if (isBenevolence) {
      history.push("applications");
    } else if (companyAdmin) {
      history.push("events");
    }
  };

  return (
    <div className="container">
      <div className="text-center" id="login-page">
        <h1>Welcome back! Please log in.</h1>
        <h6>
          For accounts without a password, please use the login url you were
          emailed to gain access to the site.
        </h6>
        <div className="login-container">
          {loginError && (
            <div className="alert alert-danger" role="alert">
              {loginError}
            </div>
          )}
          <form onSubmit={handleSubmit(attemptLogin)}>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text oi oi-person"></span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  ref={register({ required: true })}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text oi oi-lock-locked"></span>
                </div>
                <input
                  className="form-control"
                  name="password"
                  ref={register({ required: true })}
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-block"
              value="SIGN IN"
              disabled={isLoading || Object.keys(errors).length > 0}
            />

            <div className="mt-2 mb-4">
              {/* <Link to="/forgot-password">Forgot password?</Link> */}
            </div>
            <p className="px-3 mt-5">
              This portal is managed by Cotribute. By logging in, you are
              agreeing to the Cotribute{" "}
              <a
                href="https://cotribute.com/terms-of-use-cotribute"
                target="_blank"
              >
                User Agreement
              </a>
              .
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
