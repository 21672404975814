import api from "./api";

export async function download(articleId: number, submissionTaskId: number, onError: (message: string) => void) {
    try {
      const res: any = await api.post(
        `/articles/${articleId.toString()}/signed-url`, {
          submissionTaskId: submissionTaskId.toString(),
        }
      );
      window.open(res.data.url, "_blank", "noopener, noreferrer");
    } catch (e) {
      onError("Error - Unable to access document. Please try again.");
    }
}