import axios from "axios";
const clientId = "dGhyaXZlbnRyb2Nrc2FuZGNvdHJpYnV0ZWxpab_WL";
const baseURL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL,
  headers: { "X-Cotribute-Client-Id": clientId },
});

let authToken: string, refreshToken: string;

export interface Token {
  auth: string;
  refresh: string;
}

export function setTokens(token: Token) {
  authToken = token.auth;
  refreshToken = token.refresh;
  api.defaults.headers["Authorization"] = authToken;
}

export function getCurrentTokens() {
  return { refreshToken, authToken };
}

export function clearTokens() {
  authToken = "";
  refreshToken = "";
  delete api.defaults.headers["Authorization"];
}

export default api;
