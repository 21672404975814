/* DOCUMENT SWAP MODAL
 *  Is hidden until an admin wants to swap out documents ---------------------- */
import React from "react";
import { ConfirmationModal } from "../../components/confirmationModal";
import ArrowRepeat from "react-bootstrap-icons/dist/icons/arrow-repeat";
import ShieldFillExclamation from "react-bootstrap-icons/dist/icons/shield-fill-exclamation";

interface Props {
  show: boolean;
  swapDoc: () => any;
  onClose: () => any;
  currentTasks: any;
  orgTaskTemplates: any;
  docSwapSelection: number;
}

const DocSwapModal = ({
  show,
  swapDoc,
  onClose,
  currentTasks,
  orgTaskTemplates,
  docSwapSelection,
}: Props) => {
  // This guards against errors for users running a benevolence campaign, which won't have the option to
  // swap documents anyways.
  if (!currentTasks) {
    return <></>;
  }
  return (
    <ConfirmationModal
      title="Confirm Documents Swap"
      show={show}
      onClose={onClose}
      cta={
        <button onClick={swapDoc} className="btn btn-primary">
          Confirm Swap
        </button>
      }
    >
      <div className="d-flex flex-column align-items-center px-2 py-4">
        {/* Caution Icon (Shield) */}
        <ShieldFillExclamation
          style={{ fontSize: "80px" }}
          className="text-primary"
        />
        <h1 className="text-primary mt-3">Are you sure?</h1>
        <p className="mt-4">
          You are about to swap out one set of required documents for a new one.{" "}
          <span className="font-weight-bold">
            This will irreversibly delete any documents that have already been
            uploaded.
          </span>
        </p>
        {/* Yellow Swap Box - Shows items being swapped out */}
        <div className="table-warning px-4 py-2 w-100 shadow">
          <th>You Are Swapping Out:</th>
          <ul>
            {currentTasks.map((task: any) => {
              return (
                <li className="py-0 mb-0" style={{ fontSize: "12px" }}>
                  {task.title}
                </li>
              );
            })}
          </ul>
        </div>

        {/* Swap Icon */}
        <ArrowRepeat
          style={{ fontSize: "40px" }}
          className="text-secondary my-3"
        />

        {/* Green Swap Box - Shows items being swapped in */}
        <div className="table-success px-4 py-2 w-100 shadow">
          <th>You Are Swapping In:</th>
          <ul>
            {orgTaskTemplates[docSwapSelection][1].map((task: any) => {
              return (
                <li className="py-0 mb-0" style={{ fontSize: "12px" }}>
                  {task.title}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default DocSwapModal;
