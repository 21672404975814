import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Clipboard from "react-bootstrap-icons/dist/icons/clipboard-plus";
import ClipboardCheck from "react-bootstrap-icons/dist/icons/clipboard-check";

type Props = {
  text: string;
  label?: string;
  subtext?: string;
};
const ClipboardInput = ({ text, label, subtext }: Props) => {
  const [copySuccess, setCopySuccess] = useState("");
  const inputRef = useRef<any>(null);

  function copyToClipboard(e: any) {
    inputRef.current.select();
    document.execCommand("copy");

    setCopySuccess("Copied!");
  }

  const clipboardStyle = {
    width: 28,
    height: 28,
    marginTop: 3,
    marginLeft: 5,
  };

  return (
    <div>
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}

        <div style={{ display: "flex" }}>
          <Form.Control ref={inputRef} value={text} contentEditable={false} />
          {document.queryCommandSupported("copy") && (
            <div
              style={{ paddingLeft: 0, cursor: "pointer" }}
              onClick={copyToClipboard}
            >
              {copySuccess ? (
                <ClipboardCheck style={{ color: "green", ...clipboardStyle }} />
              ) : (
                <Clipboard style={clipboardStyle} />
              )}
            </div>
          )}
        </div>

        {subtext && <Form.Text className="text-muted">{subtext}</Form.Text>}
      </Form.Group>
    </div>
  );
};

export default ClipboardInput;
