import React, { FC } from "react";
import { ConfirmationModal } from "../../components/confirmationModal";
import { register } from "../../serviceWorker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { selectApplicationDetail, readyForReview } from "../../models/applications/applicationDetailSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";

interface Props {
  show: boolean;
  onClose: () => any;
}

export const ReviewModal: FC<Props> = ({ show, onClose }) => {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const {application} = useSelector(selectApplicationDetail);
  const dispatch = useDispatch();
  async function process() {
    const res: any = await dispatch(
      readyForReview(application?.id as number)
    );
    if(res.error) {
      dispatch(systemMessageSlice.actions.setMessage({message: "Error - Application Status Update Failed. Please try again later.", type: "danger"}))
    } else {
      dispatch(systemMessageSlice.actions.setMessage({message: "Application Status Has Been Updated!", type: "success"}))
    }
    onClose();
  }
  return (
    <ConfirmationModal
      show={show}
      title="Confirm Ready for Client Review"
      onClose={onClose}
      cta={
        <button
          disabled={formState.isSubmitted}
          onClick={handleSubmit(process)}
          className="btn btn-primary"
        >
          Confirm Ready for Client Review
        </button>
      }
    >
    
      <p>
        Clicking "Confirm Ready for client" will notify the Benevolence Point Person(s) at {application?.organization.name} that this application is ready for review and approval
      </p>
    </ConfirmationModal>
  );
};
