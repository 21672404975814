import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { useHistory } from "react-router-dom";
import { asyncTimeout } from "../../utils/helpers";
import { selectOrg } from "../../models/organization/organizationSlice";
import { TableColHeader } from "../../components/tableColHeader";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { Spinner } from "../../components/spinner";
import { getEvents, selectEvents } from "../../models/events/eventSlice";
import Pagination from "../../components/pagination/pagination";
import { SortOptions } from "../../models/applications/applicationInterfaces";
import { Badge, Button } from "react-bootstrap";
import { lightFormat } from "date-fns";
import { selectMyUser } from "../../models/users/myUserSlice";
import { isAdmin } from "../../utils/isAdmin";
import Unauthorized from "../Unauthorized";

const headerCols = [
  { title: "Thrivent ID", value: "custom2", sort: "custom_2" },
  { title: "Host Name", value: "host", sort: "host" },
  { title: "First Name", value: "eventOwner.first_name", sort: "first_name" },
  { title: "Last Name", value: "eventOwner.last_name", sort: "last_name" },
  { title: "Email", value: "eventOwner.email", sort: "email" },
  { title: "Project Title", value: "title", sort: "title" },
  { title: "Project Type", value: "isFundraiser", sort: "type" },
  {
    title: "Publicity Status",
    value: "visibilityStatus",
    sort: "visibility_status",
  },
  { title: "Created", value: "createdAt", sort: "created_at" },
];
const statuses = {
  publicized: {
    text: "PUBLIC",
    variant: "success",
  },
  privatized: {
    text: "PRIVATE",
    variant: "warning",
  },
  unlisted: {
    text: "UNLISTED",
    variant: "danger",
  },
} as Record<string, { text: string; variant: string }>;

const EventsDashboard: FC = () => {
  const { events, totalRecords, options } = useSelector(selectEvents);
  const { id } = useSelector(selectOrg);
  const [search, setSearch] = useState(options.search);
  const dispatch = useDispatch();
  const user = useSelector(selectMyUser);
  const history = useHistory();
  const [isLoading] = useRequestStatus(getEvents.typePrefix);

  const orgId = id!;

  const fetchEvents = useCallback((sortOptions?: SortOptions) => {
    return dispatch(getEvents({ id: orgId, sortOptions: sortOptions || {} }));
  }, []);

  async function fetchDataOnLoad() {
    await fetchEvents();
    await asyncTimeout(300);
  }

  useEffect(() => {
    fetchDataOnLoad();
  }, []);

  // Guard against non-admins viewing this page.
  if (user && !isAdmin(user)) return <Unauthorized userId={user.userId} />;

  return (
    <div id="payments-container" className="container-fluid">
      <div className="application-table-control-container row justify-content-end">
        <div className="col-lg-4 col-md-5 col-sm-12">
          <div className="d-flex justify-content-end">
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                fetchEvents({ search, offset: 0 });
              }}
            >
              <div
                className="col"
                style={{
                  marginBottom: "1rem",
                  gridGap: "1rem",
                  display: "grid",
                  gridTemplateColumns: "1fr minmax(65px, 25%)",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <Button type="submit">Search</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <GlobalFlashMessage />

      <div className="application-table">
        <Spinner show={isLoading}></Spinner>

        <table className="table table-borderless table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              {headerCols.map((h) => (
                <TableColHeader
                  title={h.title}
                  key={h.sort}
                  sort={options.sort === h.sort ? options.direction : undefined}
                  onClick={(direction: "asc" | "desc") => {
                    fetchEvents({
                      sort: h.sort,
                      direction: direction,
                      offset: 0,
                    });
                  }}
                />
              ))}
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => {
              const publicityStatus = statuses[event.visibilityStatus];

              return (
                <tr
                  key={event.id}
                  onClick={() => {
                    history.push(`events/${event.id}`);
                  }}
                >
                  <td className="new-cta"></td>
                  <td>{event.custom2}</td>
                  <td>{event.hostName}</td>
                  <td>{event.eventOwner.firstName}</td>
                  <td>{event.eventOwner.lastName}</td>
                  <td>{event.eventOwner.email}</td>
                  <td>{event.title}</td>
                  <td>
                    {event.isFundraiser ? (
                      <Badge variant="info">FUNDRAISER</Badge>
                    ) : (
                      <Badge variant="secondary">PROJECT</Badge>
                    )}
                  </td>
                  <td>
                    <Badge
                      variant={publicityStatus.variant}
                      style={{ backgroundColor: "#007bf !important" }}
                    >
                      {publicityStatus.text}
                    </Badge>
                  </td>
                  <td>
                    {lightFormat(new Date(event.createdAt), "MM/dd/yyyy")}
                  </td>
                  <td className="cta-arrow">
                    <span className="oi oi-chevron-right"></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {events.length > 0 && (
          <div className="d-flex justify-content-between px-5 pt-3 align-items-center">
            <Pagination
              sortOptions={options}
              totalRecords={totalRecords}
              fetch={fetchEvents}
              loading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsDashboard;
