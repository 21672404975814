import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useHistory, useRouteMatch } from "react-router-dom";
import { selectMyUser } from "../../models/users/myUserSlice";
import { Role } from "../../models/users/userInterfaces";
import { canAccess } from "../../utils/user";
import { selectOrg } from "../../models/organization/organizationSlice";
import { mixpanel } from "../../utils/mixpanel";
import {
  CashCoin,
  GraphUp,
  InboxFill,
  CalendarEventFill,
  CaretRightFill,
} from "react-bootstrap-icons";

import "./navigation.scss";

interface NavItem {
  title: string;
  path: string;
  roles: Role[];
}

const Navigation: FC = () => {
  const { pathname } = useLocation();
  const myUser = useSelector(selectMyUser);
  const organization = useSelector(selectOrg);
  let { url } = useRouteMatch();

  const renderIcon = (path: string) => {
    switch (path) {
      case "analytics":
        return <GraphUp />;
      case "applications":
        return <InboxFill />;
      case "payments":
        return <CashCoin />;
      case "events":
        return <CalendarEventFill />;
      default:
        return <CaretRightFill />;
    }
  };

  // Store default navItems
  const navItems = [
    {
      title: "Events",
      path: "events",
      roles: ["companyAdmin"],
    },
    {
      title: "Applications",
      path: "applications",
      roles: [
        "benevolenceAdmin",
        "benevolenceBillingAdmin",
        "benevolenceCompanyAdmin",
      ],
    },
  ] as NavItem[];

  // Add an "analytics" option if the organization has sigma dashboard url defined for it.
  if (organization.meta?.sigmaUrl) {
    navItems.unshift({
      title: "Analytics",
      path: "analytics",
      roles: [
        "companyAdmin",
        "benevolenceAdmin",
        "benevolenceBillingAdmin",
        "benevolenceCompanyAdmin",
      ],
    });
  }

  // Add a "payments" option to navItems as long as the dashboard isn't configured for Acquire BoA.
  if (!organization.meta?.acquire) {
    navItems.push({
      title: "Payments",
      path: "payments",
      roles: [
        "benevolenceAdmin",
        "benevolenceBillingAdmin",
        "benevolenceCompanyAdmin",
      ],
    });
  }

  const filteredNavItems = navItems.filter((item) => {
    if (!myUser?.roles) return false;
    return canAccess(myUser?.roles, item.roles);
  });

  const trackClick = (nav: NavItem) => {
    mixpanel.track("Button Click", {
      "Button Title": nav.title,
      "Button Path": nav.path,
      "User Id": myUser?.userId,
      "Organization ID": organization.id,
      "Organization Name": organization.name,
      "Host Name": window.location.hostname,
      "Page URL": window.location.href,
    });
  };

  return (
    <div className="nav-header">
      {filteredNavItems.map((nav) => {
        return (
          <Link to={`${url}/${nav.path}`} onClick={() => trackClick(nav)}>
            <div
              className={`nav-header-tab ${
                pathname.includes(nav.path) ? "active" : ""
              }`}
              key={nav.title}
            >
              <div className="mr-2 d-inline">{renderIcon(nav.path)}</div>
              <span>{nav.title}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
