import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { ConfirmationModal } from "../../components/confirmationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getApplicationDetail,
  selectApplicationDetail,
  approveApplication,
  denyApplication,
} from "../../models/applications/applicationDetailSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";
interface Props {
  show: boolean;
  onClose: () => any;
}
export const DenyModal: FC<Props> = ({ show, onClose }) => {
  const { register, handleSubmit, formState } = useForm({mode: "onChange"});
  const dispatch = useDispatch();
  const { application } = useSelector(selectApplicationDetail);
  async function deny(data: any) {
    const res: any = await dispatch(denyApplication({id: application?.id as number, message: data.message}));
    if(res.error) {
      dispatch(systemMessageSlice.actions.setMessage({message: "Error - Application Status Update Failed. Please try again later.", type: "danger"}))
    } else {
      dispatch(systemMessageSlice.actions.setMessage({message: "Application Status Has Been Updated!", type: "success"}))
    }
    onClose()
  }
  return show ? (
      <ConfirmationModal
        show={show}
        title="Confirm Application Denial"
        onClose={onClose}
        cta={
          <button
            disabled={formState.isSubmitted || !formState.isValid}
            onClick={handleSubmit(deny)}
            className="btn btn-primary"
          >Confirm Denial</button>
        }
      >
        <div className="form-group">
          <label>What is the reason for rejection?</label>
          <p className="text-muted">
            This will be visible to the applicant
          </p>
          <textarea
            cols={5}
            className="form-control"
            ref={register({ required: true })}
            name="message"
          />
        </div>    
      </ConfirmationModal>
  ) : (
    <></>
  );
};
