import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import api, * as API from "../../utils/api";
import * as localStorage from "../../utils/localStorage";
import jwtDecode from "jwt-decode";
import { IMG_BASE_URL } from "../../constants";
import { applicationStatusSlice } from "../applicationStatusSlice";
import { MyUser, Roles, LoginAPIResponse } from "./userInterfaces";
import { mobileCheck } from "../../utils/helpers";
import { mixpanel } from "../../utils/mixpanel";

type MyUserState = MyUser | null;

const initialState: MyUserState = null;

function jwtToRoles(jwt: string): Roles {
  const authPayload: { roles: string[] } = jwtDecode(jwt);
  return {
    benevolenceAdmin: authPayload.roles.includes("benevolenceAdmin"),
    benevolenceBillingAdmin: authPayload.roles.includes(
      "benevolenceBillingAdmin"
    ),
    benevolenceCompanyAdmin: authPayload.roles.includes(
      "benevolenceCompanyAdmin"
    ),
    companyUser: authPayload.roles.includes("companyUser"),
    companyAdmin: authPayload.roles.includes("companyAdmin"),
  };
}

export const setUserInfo = createAsyncThunk<MyUser, LoginAPIResponse>(
  "myUser/setUserInfo",
  (data, thunkApi) => {
    (window as any).Appcues.identify(data.id, { name: data.firstName });
    mixpanel.identify(data.id);
    const roles = jwtToRoles(data.token.auth);
    const isMobile = mobileCheck();
    const invalidMobileUser =
      isMobile &&
      (roles.benevolenceAdmin ||
        roles.benevolenceBillingAdmin ||
        roles.benevolenceCompanyAdmin);

    // set auth token for all future requests
    API.setTokens(data.token);
    localStorage.setTokens(data.token);
    thunkApi.dispatch(applicationStatusSlice.actions.setAuthorization(true));

    return {
      invalidMobileUser,
      roles: roles,
      email: data.email,
      orgId: data.organization.id,
      groupId: data.group.id,
      firstName: data.firstName,
      lastName: data.lastName,
      imgUrl:
        data.imageId === "avatar"
          ? null
          : `${IMG_BASE_URL}/${data.imageVersion}/${data.imageId}`,
      userId: data.id,
    };
  }
);

export const login = createAsyncThunk<
  MyUser,
  { email: string; password: string }
>("myUser/login", async ({ email, password }, thunkApi) => {
  try {
    const { data } = await api.post<LoginAPIResponse>("/login", {
      email,
      password: btoa(password),
    });
    const { organization }: RootState = thunkApi.getState() as any;

    const roles = jwtToRoles(data.token.auth);
    if (
      !roles.benevolenceAdmin &&
      !roles.benevolenceBillingAdmin &&
      !roles.benevolenceCompanyAdmin &&
      !roles.companyAdmin
    ) {
      return Promise.reject("invalid role");
    }
    const response = await thunkApi.dispatch(setUserInfo(data));
    return response.payload as MyUser;
  } catch (e) {
    return Promise.reject(e);
  }
});

export const logout = createAsyncThunk<void>(
  "myUser/logout",
  async (_, thunkApi) => {
    localStorage.clearTokens();
    API.clearTokens();

    thunkApi.dispatch(applicationStatusSlice.actions.setAuthorization(false));
    thunkApi.dispatch(myUserSlice.actions.clearUser());
  }
);

export const loginByRefreshToken = createAsyncThunk<
  void,
  { refreshToken: string }
>("myUser/loginByRefreshToken", async ({ refreshToken }, thunkApi) => {
  try {
    const { organization }: RootState = thunkApi.getState() as any;
    const { data } = await api.post(
      "/tokens/refresh-lite",
      {},
      { headers: { "X-Cotribute-Refresh-Token": refreshToken } }
    );
    thunkApi.dispatch(setUserInfo(data));
  } catch (e) {
    if (e.response.status === 401) {
      try {
        await api.post(
          "/tokens/magic-link",
          {
            path: window.location.pathname,
          },
          { headers: { "X-Cotribute-Refresh-Token": refreshToken } }
        );
      } catch (e) {
        console.error("There was an error generating a magic link", e);
      }
    }
    // Log out the user
    thunkApi.dispatch(logout());

    // Redirect the user to the login-error page
    let org = "";
    const matches = window.location.pathname.match(/organization\/(.*)\//);
    if (matches) org = matches[1];
    const redirectUrl = `/organization/${org.split("/")[0]}/login-error`;
    window.location.replace(redirectUrl);
    return Promise.reject("Unauthorized");
  }
});

export const myUserSlice = createSlice<any, { clearUser: () => null }>({
  name: "myUser",
  initialState: initialState as MyUserState,
  reducers: {
    clearUser: () => {
      return null;
    },
  },
  extraReducers: {
    [setUserInfo.fulfilled.type]: (state, { payload }) => {
      return payload;
    },
    [logout.fulfilled.type]: (state) => {
      return null;
    },
  },
});

export const selectMyUser = (state: RootState) =>
  state.myUser as MyUser | undefined;

export default myUserSlice.reducer;
