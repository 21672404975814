import React, { FC, useState, useMemo } from "react";
import { EnvelopeFill, PersonFill } from "react-bootstrap-icons";
import { Message } from "../../models/applications/applicationInterfaces";
import truncate from "truncate";
import "./messageRecord.scss";

export interface Props {
  message?: Message;
  fromAdmin?: boolean;
  className?: string;
}

export const MessageRecord: FC<Props> = ({ message, fromAdmin, className }) => {
  // Memo whether the received message is long or not.
  const MAX_BODY_LENGTH = 600;
  const isLongMessage = useMemo(
    () => message && message.body.length > MAX_BODY_LENGTH,
    [message]
  );
  const [showFullMessage, setShowFullMessage] = useState(false);

  // Guard clause against missing data
  if (!message) {
    return <></>;
  }

  // Render
  return (
    <div className={`${className} content-container col pr-0`}>
      {/* Display diffent icon and color based on if message is from user or admin */}
      <div
        className={`${
          fromAdmin
            ? "messageRecord__container_admin"
            : "messageRecord__container_user"
        } shadow-left`}
      >
        <div className="d-flex h3">
          {fromAdmin ? <PersonFill /> : <EnvelopeFill />}
          <p className="ml-3 mb-0">
            {message.user.firstName} {message.user.lastName}
          </p>
        </div>
        <p
          className={`${
            fromAdmin
              ? "messageRecord__content_admin"
              : "messageRecord__content_user"
          } messageRecord__content`}
        >
          {showFullMessage
            ? message.body
            : truncate(message.body, MAX_BODY_LENGTH)}
        </p>

        {/* Text Button - Show/Hide full message if long message */}
        {isLongMessage && (
          <div
            className="messageRecord__expandMessage"
            onClick={() => setShowFullMessage(!showFullMessage)}
          >
            {showFullMessage ? "Hide" : "Show"} Full Message
          </div>
        )}
      </div>
    </div>
  );
};
