import React, { useEffect } from "react";
import "./organization.scss";
import { Helmet } from "react-helmet";

import Header from "../../components/header";
import {
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPage from "../login";
import ForgotPasswordPage from "../login/forgotPassword";
import LoginErrorPage from "../login/loginError";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";
import { selectMyUser } from "../../models/users/myUserSlice";
import { PrivateRoute } from "../../components/PrivateRoute";
import { UnauthorizedRoute } from "../../components/UnauthorizedRoute";
import {
  selectApplicationStatus,
  initializeApp,
} from "../../models/applicationStatusSlice";
import { ApplicationRouter } from "../applications";
import { PaymentRouter } from "../payments";
import Error404 from "../404";
import EventRouter from "../events";
import { AnalyticsRouter } from "../analytics";
import Unauthorized from "../Unauthorized";

export interface OrgParams {
  id: string;
}

const OrganizationPage: React.FC = () => {
  let { id } = useParams<OrgParams>();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isInitialized } = useSelector(selectApplicationStatus);
  const myUser = useSelector(selectMyUser);
  const org = useSelector(selectOrg);
  const footerLogoURL = org.meta?.clientFooterLogo;
  const privacyPolicyURL = org.meta?.clientPrivacyPolicy;

  useEffect(() => {
    dispatch(initializeApp(id));
  }, []);

  history.listen(() => {
    (window as any).Appcues.page();
  });

  // Check that user is part of the org being displayed.
  // DISABLED JAN 3 2023 - This approach is too simple. We need an approach for clients like nuvision, that have multiple orgs accessing the portal.
  // if (myUser && org && myUser.orgId !== org.id)
  //   return <Unauthorized userId={myUser.userId} />;

  return isInitialized ? (
    <div>
      {/* Metadata for Page */}
      <Helmet>
        <title>{`${org.name} - Admin Area`}</title>
      </Helmet>
      {myUser?.invalidMobileUser && (
        <div className="container mt-5">
          <div className="alert alert-info">
            This area is not available on a mobile device.
          </div>
        </div>
      )}
      {!myUser?.invalidMobileUser && (
        <div>
          {/* Content */}
          <div style={{ minHeight: 600 }}>
            {/* If there is a valid organization name, render the appropriate page or 404 */}
            {org.name ? (
              <>
                {/* Header */}
                <Header bannerImg={org.imgUrl || ""} user={myUser} />
                <Switch>
                  {/* Routes that only a non-logged-in user should be able to access */}
                  <UnauthorizedRoute path={`${path}/login`}>
                    <LoginPage />
                  </UnauthorizedRoute>
                  <UnauthorizedRoute path={`${path}/forgot-password`}>
                    <ForgotPasswordPage />
                  </UnauthorizedRoute>
                  <UnauthorizedRoute path={`${path}/login-error`}>
                    <LoginErrorPage />
                  </UnauthorizedRoute>
                  {/* If logged in, render routes by appropriate router */}
                  <PrivateRoute path={`${path}/applications`}>
                    <ApplicationRouter />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/payments`}>
                    <PaymentRouter />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/events`}>
                    <EventRouter />
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/analytics`}>
                    <AnalyticsRouter />
                  </PrivateRoute>
                  {/* Redirect to applications if organization/:id is entered by itself */}
                  <PrivateRoute exact path={`${path}`}>
                    {id ? (
                      <Redirect
                        to={{
                          pathname: `${id}/applications`,
                        }}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: `404`,
                        }}
                      />
                    )}
                  </PrivateRoute>
                  {/* This is fallback route, but should never be hit because of it being managed in App.tsx */}
                  <Route>
                    <Redirect
                      to={{
                        pathname: `404`,
                      }}
                    />
                    <Error404 />
                  </Route>
                </Switch>
              </>
            ) : (
              <Route>
                <Redirect
                  to={{
                    pathname: `404`,
                  }}
                />
                <Error404 />
              </Route>
            )}
          </div>
          {/* Footer */}
          <div className="footer row pt-5 px-5 mx-0">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-center mb-5">
              {footerLogoURL && <img src={footerLogoURL} alt="" width={150} />}
              {privacyPolicyURL && (
                <a href={privacyPolicyURL} target="_blank" className="mt-2">
                  Privacy Policy
                </a>
              )}
            </div>
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-center text-center mb-5">
              <div className="d-flex align-items-baseline">
                <p className="mr-3" style={{ fontSize: 12 }}>
                  EMPOWERED BY
                </p>
                <a href="https://cotribute.com" target="_blank">
                  <img
                    src="https://res.cloudinary.com/cotribute/image/upload/v1652206856/cotribute-logos-2022/Cotribute_Dark_Trimmed.png"
                    alt=""
                    height={28}
                  />
                </a>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <a href="https://cotribute.com/privacy-policy" target="_blank">
                  Privacy Policy
                </a>{" "}
                <span className="mx-3"> | </span>{" "}
                <a
                  href="https://cotribute.com/terms-of-use-cotribute/"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </div>
              <a
                href="https://cotribute.com/terms-of-use-for-external-events"
                className="mt-2"
                target="_blank"
              >
                Content Submission Agreement
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default connect()(OrganizationPage);
