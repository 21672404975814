import React, { FC } from "react";
import { ApplicationDetail } from "../../models/applications/applicationInterfaces";
import { formatMoney, formatDate } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { selectOrg } from "../../models/organization/organizationSlice";

interface Props {
  application: ApplicationDetail;
}
export const SidebarApplicationDetail: FC<Props> = ({ application }) => {
  const organization = useSelector(selectOrg);

  // Hash all characters in a String through a specified length
  const hashString = (value: string, minusLength: number = 0) => {
    let hashedString = "";
    for (let i = 0; i < value.length; i++) {
      if (i < value.length - minusLength) {
        hashedString = hashedString + (value[i] !== "-" ? "X" : "-");
      } else {
        hashedString = hashedString + value[i];
      }
    }
    return hashedString;
  };

  const displayValue = (key: string, value: any) => {
    // Check values to see if booleans are used and return checkmarks instead.
    switch (value) {
      case true:
      case "true":
        return "Yes";
      case false:
      case "false":
        return "No";
      // Format values based upon specific keys or default to returning as normal.
      default:
        switch (key) {
          // Hash social security numbers.
          case "ssn":
          case "social security number":
          case "What is your social security number?":
          case "stripeVerificationSessionId":
            return <span>{hashString(value, 4)}</span>;
          default:
            // Add dollars to financial keys.
            if (
              key.includes("income") ||
              key.includes("amount") ||
              key.includes("deposit") ||
              key.includes("payment") ||
              key.includes("value") ||
              key.includes("savings") ||
              key.includes("much")
            ) {
              if (key.includes("sources of")) {
                break;
              }
              if (typeof value !== "boolean")
                return <span>{formatMoney(value)}</span>;
            }
            // Else return value as entered.
            return <span>{value}</span>;
        }
    }
  };

  return (
    <div className="px-4">
      <h6 className="h3 mb-0 text-center text-lg-left mt-5">
        Application Details
      </h6>
      <div className="sidebar-sub-section mt-2">
        <span className="meta text-uppercase">Applicant Name</span>
        <br />
        <span>
          {application.user.firstName} {application.user.lastName}
        </span>
      </div>
      {/* Display application's email if entered */}
      {application.user.email && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Email</span>
          <br />
          <span>{application.user.email}</span>
        </div>
      )}
      {/* Display applicant's phone number if entered */}
      {application.user.phone && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Phone Number</span>
          <br />
          <span>{application.user.phone}</span>
        </div>
      )}
      <div className="sidebar-sub-section">
        <span className="meta text-uppercase">
          {organization.meta?.acquire ? "Product Type" : "Category of Need"}
        </span>
        <br />
        <span>{application.category}</span>
      </div>
      {application.description && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Description</span>
          <br />
          <span>{application.description}</span>
        </div>
      )}
      {/* Display urgentNeed if application has one */}
      {application.urgentNeed && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Urgent</span>
          <br />
          <span>{application.urgentNeed ? "Yes" : "No"}</span>
        </div>
      )}
      {/* Display amount requested if applicable */}
      {application.amountRequested ? (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Amount Requested</span>
          <br />
          <span>{formatMoney(application.amountRequested)}</span>
        </div>
      ) : (
        ""
      )}
      <div className="sidebar-sub-section">
        <span className="meta text-uppercase">Application Received</span>
        <br />
        <span>{formatDate(application.createdAt)}</span>
      </div>
      {/* Map the remaining questions and answers in the column */}
      {Object.keys(application.metadata).map((k) => {
        const item = application.metadata[k];
        return (
          item.question.toLowerCase() !== "social security number" &&
          item.question.toLowerCase() !== "what’s your full name?" &&
          !item.question.toLowerCase().includes("account number") && (
            <div className="sidebar-sub-section" key={k}>
              <span className="meta text-uppercase">{item.question}</span>
              <br />
              <span>{displayValue(item.question, item.answer)}</span>
            </div>
          )
        );
      })}
    </div>
  );
};
