import React, { FC, useState } from "react";
import { User } from "../../models/users/userInterfaces";
import {
  ActivityItem,
  NotificationType,
  Application,
} from "../../models/applications/applicationInterfaces";
import { sortBy } from "lodash";
import { ActivityFeedItem } from "./activityItem";

interface Props {
  activity: ActivityItem[];
  application: Application;
}
export const Activity: FC<Props> = ({ activity, application }) => {
  return (
    <div id="activity-container">
      <div className="activity-feed">
        {sortBy(activity, "createdAt")
          .reverse()
          .map((item) => (
            <ActivityFeedItem activity={item} key={item.id} />
          ))}
        {/* The first item (bottom of the list) is always "application received" */}
        <ActivityFeedItem
          activity={{
            id: 0,
            timestamp: application.createdAt,
            payload: {
              icon: "document-text-outline",
              notificationType: NotificationType.application_received,
            },
          }}
        />
      </div>
    </div>
  );
};
