import React, { FC } from "react";
import { Article } from "../../models/applications/applicationInterfaces";
import { articleTypeToIcon, formatBytes } from "../../utils/helpers";
import "./file.scss";

interface Props {
  article: Article;
  onDelete?: () => any;
  onDownload: () => void;
  canEdit: boolean;
}

export const File: FC<Props> = ({ article, onDelete, onDownload, canEdit }) => {
  return (
    <div className="file-container">
      <div className="dropdown">
        <div
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className="d-flex"
          style={{ userSelect: "none" }}
        >
          <img
            src={articleTypeToIcon[article.type || "IMAGE"]}
            className="my-auto"
          />
          <div
            className="col"
            style={{
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span>{article.title}</span>
            <br />
            <span className="text-muted">
              {formatBytes(article.fileSize)}{" "}
              {article.url.split(".")[article.url.split(".").length - 1]}
            </span>
          </div>
          <ion-icon name="ellipsis-vertical" style={{ height: "auto" }} />
        </div>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenu2"
        >
          <a
            className="dropdown-item"
            href="javascript:void(0);"
            onClick={onDownload}
          >
            Download
          </a>
          {canEdit && (
            <a
              className="dropdown-item"
              href="javascript:void(0);"
              onClick={onDelete}
            >
              Delete
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

File.defaultProps = {
  onDelete: () => {},
};
