import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Event } from "../../models/events/eventInterfaces";
import api from "../../utils/api";
import { Spinner } from "../../components/spinner";
import { preparePayloadForSubmission } from "./util";
import ClipboardInput from "../../components/clipboardInput";
import EyeFilled from "react-bootstrap-icons/dist/icons/eye-fill";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { useDispatch } from "react-redux";

const genericFetchError =
  "Something went wrong fetching this event. Please try again or contact support.";
const genericPutError =
  "Something went wrong saving this event. Please try again or contact support.";

const EditEvent = () => {
  const containerRef = useRef<any>(null);
  const { eventId, id: orgId } = useParams<{ eventId: string; id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [fetchError, setFetchError] = useState("");
  const [saveError, setSaveError] = useState("");
  const [eventPayload, setEvent] = useState<undefined | Event>();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const formRef = useRef<any>(null);
  const ownerHash = eventPayload?.eventOwnerRefreshToken;
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:2200"
      : window.location.origin;

  const eventUrl = `${baseUrl}/events/${eventId}/detail`;

  const fetchEvent = () => {
    api
      .get(`/events/${eventId}`)
      .then((res: { data: Event }) => {
        setEvent(res.data);
      })
      .catch((err) => {
        console.error(err);
        setFetchError(genericFetchError);
      })
      .finally(() => setLoading(false));
  };

  const updateEvent = (data: Event) => {
    setLoading(true);
    setSaveError("");
    api
      .patch(`/events/${eventId}`, data)
      .then((res) => {
        setEvent(res.data);
        dispatch(
          systemMessageSlice.actions.setMessage({
            message: "Event Saved!",
            type: "success",
          })
        );
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      })
      .catch((err) => {
        console.error(err);
        setSaveError(genericPutError);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form: HTMLFormElement = formRef.current;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
      return;
    }

    // Convert to form data.
    const data = new FormData(formRef.current);
    // Set empty struct to build object of the new values.
    const newValues: any = {};

    // Iterate over the form fields and append them to the new object.
    for (let [key, value] of data as any) {
      newValues[key] = value;
    }
    const eventPayload = preparePayloadForSubmission(event, newValues);

    setValidated(true);
    updateEvent(eventPayload);
  };

  if (fetchError) return simpleError(fetchError);
  if (!eventPayload) return <Spinner show />;

  const event = eventPayload!;

  return (
    <div
      style={{ maxWidth: 800, margin: "auto", marginTop: 20 }}
      ref={containerRef}
    >
      <GlobalFlashMessage />
      <Spinner show={loading} fullscreen lockScroll />
      <Breadcrumb style={{ position: "relative" }}>
        <Breadcrumb.Item
          onClick={() => history.push(`/organization/${orgId}/events`)}
        >
          Events
        </Breadcrumb.Item>
        {event && <Breadcrumb.Item active>{event.name}</Breadcrumb.Item>}
        <Button
          type="button"
          size="sm"
          style={{ position: "absolute", right: 8, top: 8 }}
          href={eventUrl}
          target="_blank"
        >
          <EyeFilled /> View
        </Button>
      </Breadcrumb>
      {ownerHash && (
        <Form
          style={{
            background: "white",
            padding: 20,
            borderRadius: 5,
            fontSize: 14,
            marginBottom: 20,
          }}
        >
          <Row className="col-12">
            <Col>
              <ClipboardInput
                text={eventUrl + `?hash=${ownerHash}`}
                label="Copy Magic Link"
                subtext="This link will automatically log this user in to their event page
              without requiring a username or password. Only share it with the
              event owner."
              />
            </Col>
          </Row>
        </Form>
      )}

      <Form
        validated={validated}
        onSubmit={handleSubmit}
        ref={formRef}
        style={{
          background: "white",
          padding: 20,
          borderRadius: 5,
          fontSize: 14,
        }}
      >
        <h4 style={{ marginBottom: 30 }}>Editing: {event.name}</h4>
        <Form.Group controlId="eventTitle">
          <Form.Label>Event Title</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Title"
            maxLength={50}
            required
            defaultValue={event.name}
          />
        </Form.Group>
        <Form.Group controlId="eventDescription">
          <Form.Label>Event Description</Form.Label>
          <Form.Control
            name="description"
            type="text"
            as="textarea"
            placeholder="Description"
            required
            defaultValue={event.description}
          />
        </Form.Group>
        <Form.Group controlId="hostName">
          <Form.Label>Host Name</Form.Label>
          <Form.Control
            name="host"
            type="text"
            placeholder="Host"
            required
            defaultValue={event.host}
          />
        </Form.Group>
        <Form.Group controlId="location">
          <Form.Label>Location</Form.Label>
          <Form.Control
            name="location"
            type="text"
            placeholder="Location"
            defaultValue={event.location?.name || ""}
          />
        </Form.Group>
        <Form.Group controlId="charity">
          <Form.Label>In Support of Name</Form.Label>
          <Form.Control
            name="charity"
            type="text"
            maxLength={50}
            placeholder="Charity Name"
            required
            defaultValue={event.charity?.name || ""}
          />
        </Form.Group>
        <Form.Group controlId="eventOwnerNotifications">
          <Form.Label>Event Owner Notifications</Form.Label>
          <Form.Check
            value="true"
            name="eventOwnerNotifications"
            type="switch"
            defaultChecked={event.eventOwnerNotifications}
          />
        </Form.Group>
        {event.isFundraiser && (
          <Form.Group controlId="fundraiserGoal">
            <Form.Label>Fundraiser Goal Amount</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="fundraiserGoal"
                type="number"
                step="1"
                min="1"
                placeholder="Enter an Amount Greater than 0"
                required
                defaultValue={Number(event.campaign?.goal || 0).toFixed(0)}
              />
            </InputGroup>
          </Form.Group>
        )}

        <Form.Group controlId="visibilityStatus">
          <Form.Label>Publicity Status</Form.Label>
          <Form.Control
            style={{
              backgroundImage: "none",
            }}
            as="select"
            name="visibilityStatus"
            required
            defaultValue={event.visibilityStatus}
          >
            <option value="0">Public - Visible to all users</option>
            <option value="1">Private - Only visible to the event owner</option>
            <option value="2">
              Unlisted - Only visible via direct linking
            </option>
          </Form.Control>
        </Form.Group>
        {saveError && <p style={{ color: "#d91a32" }}>{saveError}</p>}
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </div>
  );
};

const simpleError = (err: string) => (
  <h5
    style={{
      color: "#d91a32",
      textAlign: "center",
      maxWidth: 400,
      margin: "auto",
      marginTop: 50,
    }}
  >
    {err}
  </h5>
);

export default EditEvent;
