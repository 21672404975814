import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { ConfirmationModal } from "../../components/confirmationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getApplicationDetail,
  selectApplicationDetail,
  approveApplication,
} from "../../models/applications/applicationDetailSlice";
import { formatMoney } from "../../utils/helpers";
import { systemMessageSlice } from "../../models/systemMessageSlice";
interface Props {
  show: boolean;
  onClose: () => any;
}
export const ApprovalModal: FC<Props> = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const { application } = useSelector(selectApplicationDetail);
  const { register, handleSubmit, formState, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      allowAdditionalFunds: "No",
      description: application?.description,
      amountApproved: application?.amountRequested,
      title: application?.category,
    } as any,
  });
  const formValues = watch();

  async function approve(data: any) {
    let approvalData = { ...data };
    delete approvalData.allowAdditionalFunds;
    approvalData.amountMatch = +approvalData.amountMatch || 0;
    approvalData.amountCampaign = +approvalData.amountCampaign || 0;
    approvalData.amountApproved = +approvalData.amountApproved || 0;
    const res: any = await dispatch(
      approveApplication({
        id: application?.id as number,
        payload: { ...approvalData },
      })
    );
    if (res.error) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message:
            "Error - Application Status Update Failed. Please try again later.",
          type: "danger",
        })
      );
    } else {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Application Status Has Been Updated!",
          type: "success",
        })
      );
    }
    onClose();
  }
  const allowAdditionalFunds = formValues.allowAdditionalFunds === "Yes";
  return show && application ? (
    <ConfirmationModal
      show={show}
      title="Complete Application Approval"
      onClose={onClose}
      cta={
        <button
          disabled={formState.isSubmitted || !formState.isValid}
          onClick={handleSubmit(approve)}
          className="btn btn-primary"
        >
          Confirm Approval
        </button>
      }
    >
      <div className="form-group">
        <label>What amount is approved for immediate payout?</label>
        <p className="text-muted">
          {application?.organization.name} allows up to{" "}
          {formatMoney(
            application.organization.meta.benevolenceAdminArea
              .maxGuaranteedAmount
          )}
        </p>
        <input
          type="number"
          className="form-control"
          ref={register({ required: true })}
          name="amountApproved"
        />
      </div>
      <div className="form-group">
        {formValues.amountApproved < application.amountRequested && (
          <div className="mb-3">
            <label>
              Would you like to invite this applicant to use the anonymous
              benevolence campaign to raise the additional funds to meet the
              requested amount?
            </label>
            <div className="form-check form-check-inline">
              <input
                name="allowAdditionalFunds"
                type="radio"
                value="Yes"
                ref={register({ required: true })}
              />
              <label className="form-check-label ml-1">Yes</label>
            </div>

            <div className="form-check form-check-inline">
              <input
                name="allowAdditionalFunds"
                type="radio"
                value="No"
                ref={register({ required: true })}
              />
              <label className="form-check-label ml-1">No</label>
            </div>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="">Give this need a title:</label>
          {!allowAdditionalFunds && (
            <p className="text-muted">
              This will appear on the graphic on the Community Board,
              celebrating the support. Do not use the name of the applicant in
              the Title.
            </p>
          )}
          {allowAdditionalFunds && (
            <p className="text-muted">
              This will appear on the campaign page. The campaign must remain
              100% anonymous. Do not use the name of the applicant in the
              description.
            </p>
          )}
          <input
            type="text"
            className="form-control"
            ref={register({ required: true, minLength: 5 })}
            name="title"
          />
          {errors.title?.type === "minLength" && (
            <span className="text-danger">
              Title must be 5 characters minimum
            </span>
          )}
        </div>

        {allowAdditionalFunds && (
          <>
            <div className="form-group">
              <label htmlFor="">What should be the goal of the campaign?</label>
              <p className="text-muted">
                {application?.user.firstName} requested{" "}
                {formatMoney(application.amountRequested)}. The approved payout
                of {formatMoney(watch().amountApproved)} will be shown as a
                donation on the campaign.
              </p>
              <input
                type="number"
                className="form-control"
                ref={register()}
                name="amountCampaign"
              />
            </div>

            <div className="form-group">
              <label htmlFor="">Include a match on the campaign?</label>
              <p className="text-muted">
                {application?.organization.name} default match is{" "}
                {formatMoney(
                  application.organization.meta.benevolenceAdminArea
                    .defaultMatchAmount
                )}
              </p>
              <input
                type="number"
                className="form-control"
                ref={register()}
                name="amountMatch"
              />
            </div>

            <div className="form-group">
              <label htmlFor="">
                Edit the description of the campaign as needed:
              </label>
              <p className="text-muted">
                This will appear on the campaign page. The campaign must remain
                100% anonymous. Do not use the name of the applicant in the
                description.
              </p>
              <textarea
                rows={10}
                className="form-control"
                ref={register()}
                name="description"
              />
            </div>
          </>
        )}
        <div className="form-group">
          {allowAdditionalFunds && (
            <p className="text-muted">
              Clicking "Confirm Approval" will create an approved payment of{" "}
              {formatMoney(formValues.amountApproved)} notify{" "}
              {application?.user.firstName} via email and SMS, and invite{" "}
              {application?.user.firstName} to an anonymous benevolence campaign
              with a {formatMoney(watch().amountCampaign)} goal and{" "}
              {formatMoney(watch().amountMatch)} match by{" "}
              {application?.organization.name}
            </p>
          )}
          {!allowAdditionalFunds && (
            <p className="text-muted">
              Clicking "Confirm Approval" will create an approved payment of{" "}
              {formatMoney(formValues.amountApproved)}, and a graphic to the{" "}
              {application?.organization.name} community board celebrating the
              support, and notify notify {application?.user.firstName} via email
              and SMS.
            </p>
          )}
        </div>
      </div>
    </ConfirmationModal>
  ) : (
    <></>
  );
};
