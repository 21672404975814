import { User, UserAPIResponse } from "../users/userInterfaces";
import {
  Organization,
  OrganizationDetail,
} from "../organization/organizationInterfaces";

export enum ApplicationCategory {
  MEDICAL_BILL_FUND = "Medical Bill Fund",
}

export enum EventStatus {
  PENDING_APPLICATION_APPROVAL = "Pending Applicant Approval",
  IN_PROGRESS = "In Process",
  COMPLETE = "complete",
}
interface Event {
  id: number;
  product: { id: number };
  status: EventStatus;
  title: string;
  custom?: {
    meta: {
      fullyFunded: boolean;
    };
  };
}
type metaQuestions = { [key: string]: { question: string; answer: string } };
export interface ApplicationDetail extends Application {
  organization: OrganizationDetail;
  lastSubmission: string | null;
  metadata: metaQuestions;
  event?: Event;
}
export interface Application {
  id: number;
  organization: Organization;
  benevolenceAdmin: {
    id: number;
    firstName: string;
    lastName: string;
  };
  benevolenceOrganization: Organization;
  user: User;
  category: ApplicationCategory;
  phone: string;
  description: string;
  amountRequested: number;
  amountCampaign: number;
  amountApproved: number;
  amountMatch: number;
  urgentNeed: boolean;
  lastActivity: string;
  lastViewed: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  archived?: boolean;
}

export interface SortOptions {
  search?: string;
  status?: string;
  assignedTo?: number;
  offset?: number;
  sort?: string;
  direction?: "asc" | "desc";
  includeArchived?: boolean;
}

export interface NotesSortOptions {
  offset?: number;
  sort?: string;
  direction?: "asc" | "desc";
}
export interface Questions {
  prompt: string;
  required: boolean;
  response: string;
}

export interface Task {
  articles: Article[];
  createdAt: string;
  documentRequired: boolean;
  id: number;
  isBill: boolean;
  questions: Questions[];
  status: string;
  title: string;
  updatedAt: string;
  tooltipDescription: string;
  tooltipTitle: string;
  link: string;
}

export interface Note {
  id?: number;
  subjectType?: string;
  subjectId?: number;
  title?: string;
  body?: string;
  user?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    imageId?: string;
    imageVersion?: string;
    meta?: {
      location?: string;
    };
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface NotesAPIResponse {
  totalRecords: number;
  records: Note[];
}
export interface TasksAPIResponse {
  records: [
    {
      articles: ArticleAPIResponse[];
      createdAt: string;
      documentRequired: boolean;
      id: number;
      isBill: boolean;
      tooltipDescription: string;
      tooltipTitle: string;
      metadata: {
        questions: Questions[];
      };
      status: string;
      title: string;
      updatedAt: string;
      link: string;
    }
  ];
}
export interface ApplicationAPIResponse {
  id: number;
  organization: {
    id: number;
    name: string;
    imageVersion: string;
    imageId: string;
    metadata: {
      benevolenceAdminArea: {
        applicationTimeLimit: string;
        sendToBillCom: boolean;
        defaultMatchAmount: string;
        maxGuaranteedAmount: string;
        requireClientReview: string;
      };
    };
  };
  benevolenceAdmin: {
    id: number;
    firstName: string;
    lastName: string;
  };
  event?: Event;
  benevolenceOrganization: {
    id: number;
    name: string;
    imageVersion: string;
    imageId: string;
  };
  metadata: metaQuestions;
  user: UserAPIResponse;
  category: ApplicationCategory;
  phone: string;
  lastSubmission: string | null;
  description: string;
  amountRequested: number;
  amountCampaign: number;
  amountApproved: number;
  amountMatch: number;
  urgentNeed: boolean;
  lastActivity: string;
  lastViewed: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface ApplicationsAPIResponse {
  records: ApplicationAPIResponse[];
  totalRecords: number;
}

export enum NotificationType {
  application_received = "application_received",
  new_benevolence_submission_to_applicant = "new_benevolence_submission_to_applicant",
  new_benevolence_submission_to_assigned_benevolence_partner = "new_benevolence_submission_to_assigned_benevolence_partner",
  benevolence_submission_tasks_incomplete = "benevolence_submission_tasks_incomplete",
  benevolence_submission_task_completed_by_applicant = "benevolence_submission_task_completed_by_applicant",
  benevolence_submission_all_tasks_completed_by_applicant = "benevolence_submission_all_tasks_completed_by_applicant",
  benevolence_submission_approved = "benevolence_submission_approved",
  benevolence_submission_denied = "benevolence_submission_denied",
  benevolence_submission_denied_notice_sent = "benevolence_submission_denied_notice_sent",
  benevolence_submission_ready_for_client_review = "benevolence_submission_ready_for_client_review",
  benevolence_submission_ready_for_client_review_notice_sent = "benevolence_submission_ready_for_client_review_notice_sent",

  new_comment_to_benevolence_applicant = "new_comment_to_benevolence_applicant",
  submission_export = "submission_export",
  new_comment_to_assigned_benevolence_partner_admin = "new_comment_to_assigned_benevolence_partner_admin",
  benevolence_payment_paid = "benevolence_payment_paid",
  benevolence_payment_created = "benevolence_payment_created",
  send_benevolence_payment_to_external_source = "send_benevolence_payment_to_external_source",
  payment_export = "payment_export",
  event_created_to_event_owner = "event_created_to_event_owner",
  event_created_to_event_owner_notice_sent = "event_created_to_event_owner_notice_sent",
  benevolence_submission_task_incomplete_day_3_reminder = "benevolence_submission_task_incomplete_day_3_reminder",
  benevolence_submission_task_incomplete_day_7_reminder = "benevolence_submission_task_incomplete_day_7_reminder",
  benevolence_submission_task_incomplete_day_14_reminder = "benevolence_submission_task_incomplete_day_14_reminder",
  submission_decision_status_updated = "submission_decision_status_updated",
  submission_decision_status_auto_updated = "submission_decision_status_auto_updated",
}
export interface ApplicationActivityAPIResponse {
  records: [
    {
      id: number;
      timestamp: string;
      fromUser?: UserAPIResponse;
      type: string;
      payload: {
        icon: string;
        notification_type: NotificationType;
        submission_payment: {
          amount: number;
        };
        denial_message?: string;
        submission_id: number;
        message?: {
          description: string;
        };
        campaign_information?: [{ description: string; value: string }];
      };
    }
  ];
}

export interface ActivityItem {
  id: number;
  timestamp: string;
  fromUser?: { id: number; firstName: string; lastName: string };
  type?: string;

  payload: {
    icon: string;
    paymentAmount?: number;
    notificationType: NotificationType;
    message?: string;
    denialMessage?: string;
    campaignInformation?: [{ description: string; value: string }];
    oldDecisionStatus?: string;
    newDecisionStatus?: string;
    newAdmin?: { id: number; firstName?: string; lastName?: string };
    oldAdmin?: { id: number; firstName?: string; lastName?: string };
    decisionRuleTitle?: string;
    archived?: boolean;
  };
}

export interface Article {
  fileSize: number;
  id: number;
  type: string;
  title: string;
  updatedAt: string;
  url: string;
}

export interface Message {
  body: string;
  createdAt: string;
  id: number;
  updatedAt: string;
  user: MessageUser;
}

export interface MessageUser {
  firstName: string;
  id: number;
  imageId: string;
  imageVersion: number;
  lastName: string;
}

export interface ArticleAPIResponse {
  fileSize: number;
  id: number;
  articleType: {
    article_type: string;
  };
  title: string;
  updatedAt: string;
  url: string;
}
