import React, { FC, useCallback, useEffect, useState } from "react";
import Pagination from "../../components/pagination/pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPayments,
  getPayments,
} from "../../models/payments/paymentsSlice";
import { useRequestStatus } from "../../models/asyncRequestStatusReducer";
import { useHistory } from "react-router-dom";
import {
  statusToTitle,
  formatDate,
  formatMoney,
  paymentStatusToTitle,
  asyncTimeout,
} from "../../utils/helpers";
import { selectOrg } from "../../models/organization/organizationSlice";
import api from "../../utils/api";
import { TableColHeader } from "../../components/tableColHeader";
import { selectMyUser } from "../../models/users/myUserSlice";
import { GlobalFlashMessage } from "../../components/flashMessage";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { Spinner } from "../../components/spinner";
import { SortOptions } from "../../models/applications/applicationInterfaces";
import { isAdmin } from "../../utils/isAdmin";
import Unauthorized from "../Unauthorized";

const headerCols = [
  { title: "ID", value: "id" },
  { title: "Client", value: "client" },
  { title: "First Name", value: "first_name" },
  { title: "Last Name", value: "last_name" },
  { title: "Application", value: "application_id" },
  { title: "Payout Recipient", value: "recipient_name" },
  { title: "Approved Payout", value: "amount_approved" },
  { title: "Approved", value: "created_at" },
  { title: "Status", value: "status" },
];

export const PaymentsDashboard: FC = () => {
  const { payments, totalRecords, options } = useSelector(selectPayments);
  const [search, setSearch] = useState(options.search);
  const dispatch = useDispatch();
  let history = useHistory();
  const myUser = useSelector(selectMyUser);
  const organization = useSelector(selectOrg);
  const [isLoading] = useRequestStatus(getPayments.typePrefix);
  let filter = "";

  if (options.assignedTo) filter = "assignedToMe";
  if (options.status) filter = options.status;

  const fetchPayments = useCallback((sortOptions?: SortOptions) => {
    return dispatch(getPayments(sortOptions || {}));
  }, []);

  async function fetchDataOnLoad() {
    await fetchPayments({});
    await asyncTimeout(300);
  }
  useEffect(() => {
    fetchDataOnLoad();
  }, []);

  // Guard against non-admins viewing this page.
  if (myUser && !isAdmin(myUser))
    return <Unauthorized userId={myUser.userId} />;

  return (
    <div id="payments-container" className="container-fluid">
      <div className="application-table-control-container row justify-content-between">
        <div className="col-4">
          <div className="form-group row control-filter align-items-center">
            <label className="form-check-label px-3">View</label>
            <select
              className="custom-select col"
              value={filter}
              onChange={(e) => {
                if (!e.target.value) {
                  return fetchPayments({
                    assignedTo: undefined,
                    status: undefined,
                    offset: 0,
                  });
                }
                if (e.target.value === "assignedToMe") {
                  return fetchPayments({
                    assignedTo: myUser?.userId,
                    status: undefined,
                    offset: 0,
                  });
                }
                return fetchPayments({
                  assignedTo: undefined,
                  status: e.target.value,
                  offset: 0,
                });
              }}
            >
              <option value="">All</option>
              <option value="assignedToMe">Assigned to Me</option>
              {Object.keys(paymentStatusToTitle).map((status) => {
                return (
                  <option value={status} key={status}>
                    {paymentStatusToTitle[status]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-end">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  fetchPayments({ search: e.target.value, offset: 0 });
                }}
              />
            </div>
            {myUser?.roles.benevolenceBillingAdmin && (
              <button
                className="btn btn-primary"
                onClick={async () => {
                  try {
                    await api.post(
                      `/reports/organizations/${organization.id}/submission-payments/download`
                    );
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message: ` Please Check ${myUser?.email} in the Next 15 minutes For your Exported File!`,
                        type: "success",
                      })
                    );
                  } catch (e) {
                    dispatch(
                      systemMessageSlice.actions.setMessage({
                        message:
                          "Error - Payment Export Failed. Please try again later.",
                        type: "danger",
                      })
                    );
                  }
                }}
              >
                Export CSV
              </button>
            )}
          </div>
        </div>
      </div>
      <GlobalFlashMessage />

      <div
        className="application-table"
        style={{ position: "relative", minHeight: 600 }}
      >
        <Spinner show={isLoading}></Spinner>
        <table className="table table-borderless table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              {headerCols.map((h) => (
                <TableColHeader
                  title={h.title}
                  key={h.value}
                  sort={
                    options.sort === h.value ? options.direction : undefined
                  }
                  onClick={(direction: "asc" | "desc") => {
                    fetchPayments({ sort: h.value, direction: direction });
                  }}
                />
              ))}
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => {
              return (
                <tr
                  key={payment.id}
                  onClick={() => {
                    history.push(`payments/${payment.id}`);
                  }}
                >
                  <td className="new-cta"></td>
                  <td>{payment.id}</td>
                  <td>{payment.organization?.name}</td>
                  <td>{payment.user.firstName}</td>
                  <td>{payment.user.lastName}</td>
                  <td>{payment.applicationId}</td>
                  <td>{payment.recipientName}</td>
                  <td>{formatMoney(payment.amountApproved)}</td>
                  <td>{formatDate(payment.createdAt)}</td>
                  <td>{statusToTitle[payment.status]}</td>
                  <td className="cta-arrow">
                    <span className="oi oi-chevron-right"></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {payments.length > 0 && (
          <div className="d-flex justify-content-between px-5 pt-3 align-items-center">
            <Pagination
              loading={isLoading}
              fetch={fetchPayments}
              sortOptions={options}
              totalRecords={totalRecords}
            />
          </div>
        )}
      </div>
    </div>
  );
};
