import { useRouteMatch, Switch } from "react-router-dom";
import React from "react";
import { PrivateRoute } from "../../components/PrivateRoute";
import EventsDashboard from "./dashboard";
import EditEvent from "./edit";

const EventRouter = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <EventsDashboard />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:eventId`}>
        <EditEvent />
      </PrivateRoute>
    </Switch>
  );
};

export default EventRouter;
